<template>
  <div class="flex">
    <div class="upload">
      <div>
        <div>
          <div class="container-upload">
            <r-icon
              class="mr-2"
              icon="warning"
              fill="fargo"
              size="56"
            />
            <h1 class="ricotta mb-2">
              Удалить точку сбора ТКО?
            </h1>
            <div class="mb-6 font">
              Все данные о контейнере по адресу {{ item.address }} будут удалены.
            </div>
            <div class="mt-2 buttons">
              <r-button
                class="flex-1"
                type="secondary"
                @click="close()"
                width="wide"
                title="Не удалять"
              />
              <r-button
                class="flex-1"
                @click.native="deleteTko"
                width="wide"
                title="Удалить"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DeleteModal',
  props: {
    item: {
      type: Object
    }
  },
  data() {
    return {
      upload: false
    };
  },
  methods: {
    close() {
      document.querySelectorAll('.RModal__close')[0].click();
    },
    deleteTko() {
      this.$store.dispatch('loadDelete', this.$props.item).then(result => {
        const r = this.$router.resolve({
          name: 'index'
        });
        if (!result?.error) {
          window.location.assign(r.href);
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.buttons {
  display: grid;
  grid-template-columns: 380px 380px;
  grid-gap: 32px;
  @media(max-width: 792px) {
    grid-template-columns: 1fr;
  }
}
.upload {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
}
.container-upload {
  width: 800px;
}
</style>
<style>
.RModal__close {
  z-index: 1000;
}
</style>
