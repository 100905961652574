<template>
  <div class="flex">
    <div class="upload">
      <div>
        <div>
          <div class="container-upload">
            <r-icon
              class="mb-6"
              icon="warning"
              size="56"
              fill="fargo"
            />
            <div class="camembert mb-4 titanic--text">
              {{ title }}
            </div>
            <div class="parmigiano mb-8">
              {{ subTitle }}
            </div>
            <div class="flex align-center justify-space-between">
              <r-button
                class="mr-8"
                @click="close()"
                width="wide"
                type="secondary"
                :title="titleBtnReset"
              />
              <r-button
                @click="returnResult(true)"
                width="wide"
                :title="titleBtnConfirm"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ConfirmModal',
  props: {
    title: {
      type: String
    },
    subTitle: {
      type: String
    },
    titleBtnConfirm: {
      type: String
    },
    titleBtnReset: {
      type: String
    },
    id: {
      type: Number
    },
    saveFunc: Function
  },
  methods: {
    close() {
      document.querySelectorAll('.RModal__close')[0].click();
      this.$emit('click', this.id);
    },
    returnResult(result) {
      document.querySelectorAll('.RModal__close')[0].click();
      this.saveFunc(this.id);
    }
  }
};
</script>
<style lang="scss" scoped>
.buttons {
  display: grid;
  grid-template-columns: 380px 380px;
  grid-gap: 32px;
  @media(max-width: 792px) {
    grid-template-columns: 1fr;
  }
}
.upload {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
}
.container-upload {
  width: 800px;
}
</style>
<style>
.RModal__close {
  z-index: 1000;
}
</style>
