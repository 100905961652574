<template>
  <div
    ref="content"
    :class="{'button_fly': flyButton}"
  >
    <div
      class="flex sulguni mb-7 print pointer"
      @click="linkAdmin()"
    >
      <r-icon
        class="mr-2"
        icon="arrow-left"
        style="margin-top: 2px;"
      />
      Назад
    </div>
    <loader v-if="isLoading" />
    <div
      v-else
      :key="countInput"
    >
      <div>
        <r-bulb
          :is-block="true"
          :is-shape="false"
          color="fargo"
          :title="!isSave ? 'Не сохрвнено' : ''"
          position="eluno"
          size="marina"
        >
          <template #content>
            <h1 class="ricotta h-tko">
              {{ typeName }}
            </h1>
          </template>
        </r-bulb>
      </div>
      <div>
        <div class="header-link">
          <div
            :class="information ? `mr-4 float link-information` : `mr-4 float link-information street`"
            @click="informationFunc()"
          >
            Общая информация
            <div :class="information ? 'line' : ''" />
          </div>
          <div
            :class="observation ? `mr-4 float link-information` : `mr-4 float link-information street`"
            @click="observationFunc()"
          >
            Наблюдение
            <div :class="observation ? 'line' : ''" />
          </div>
          <!-- <div
            :class="sensor ? `mr-4 float link-information` : `mr-4 float link-information street`"
            @click="sensorFunc()">
            Датчик
            <div :class="sensor ? 'line' : ''">
            </div>
          </div>
          -->
        </div>
      </div>
      <loader v-if="isLoading" />
      <div
        v-else
        :key="count"
      >
        <div class="mt-6 flex flex-1">
          <div
            class="flex flex-1"
            style="flex-direction: column;"
          >
            <div v-show="information">
              <r-select
                v-model="items['type']"
                :items="sort"
                return-object
                label="Тип"
                class="flex-1"
                @input="chengeType(items['type'])"
              />
              <r-select
                :disabled="items['type'].id != 5"
                label="Виды мусора"
                v-model="model"
                :items="sortType"
                class="flex-1 mt-5 opacity"
                @input="chenge"
                return-object
              />
              <div>
                <r-input
                  class="flex-2 mt-5  time-days float margin-bottom"
                  value=""
                  v-model="items['volume']"
                  label="Объем м³"
                  :params-input="{&quot;type&quot;:&quot;input&quot;}"
                />
                <r-input
                  class="flex-2 mt-5 ml-5 time-days float margin-bottom"
                  value=""
                  v-model="items['amount']"
                  label="Количество"
                  :params-input="{&quot;type&quot;:&quot;input&quot;}"
                />
              </div>
              <h2 class="taleggio ">
                График вывоза ТКО
              </h2>
              <div class="flex">
                <r-select
                  label="Дни"
                  v-model="modelDays"
                  :items="sortDay"
                  class="flex-1 mt-5 opacity"
                  @input="chengeDay"
                  return-object
                />
              </div>
              <div class="flex mt-5">
                <r-time-picker
                  v-model="timeTo"
                  label="Время от"
                  @input="chengeSchedule"
                  class="flex-1 mr-5"
                />
                <r-time-picker
                  v-model="timeFrom"
                  class="flex-1"
                  @input="chengeSchedule"
                  label="Время до"
                />
              </div>
              <r-tooltip
                position="center-right"
                :is-arrow="false"
                align-title="end"
                max-width="200px"
                title="Вы можете указать дату и время графика вызова в свободной форме. Тогда поля &quot;Дни&quot; и &quot;Время вывоза&quot; не учитываются."
              >
                <template #activator>
                  <r-input
                    class="flex-1 mt-5 mb-10 float custom other"
                    after-icon="help"
                    v-if="items['extraData'] != null"
                    :style="time ? 'margin-top: -140px;' : ''"
                    v-model="items.schedule"
                    label="Другое"
                    :params-input="{&quot;type&quot;:&quot;input&quot;}"
                  />
                </template>
              </r-tooltip>
              <h2
                class="taleggio mb-5"
                :style="time ? 'margin-top: -60px;' : ''"
              >
                Площадка
              </h2>
              <r-input
                class="flex-1 float custom mb-5"
                :style="time ? 'margin-top: -16px;' : ''"
                value=""
                v-model="items['coverage']"
                label="Используемое покрытие"
                :params-input="{&quot;type&quot;:&quot;input&quot;}"
              />
              <r-checkbox
                class="mt-1 float-left"
                :value="isCheck ? '' : []"
                title="Ограждение"
                @input="funcCheck"
              />
              <div class="text-covid">
                <r-input
                  class="flex-1 ml-3 mb-5 opacity"
                  value=""
                  v-model="items['fence']"
                  label="Материал и тип ограждения"
                  :params-input="{&quot;type&quot;:&quot;input&quot;}"
                  :readonly="!isCheck"
                />
              </div>
              <r-input
                class="flex-1 custom mb-10 "
                value=""
                v-model="items['square']"
                label="Площадь м²"
                :params-input="{&quot;type&quot;:&quot;input&quot;}"
                :readonly="!isCheck"
              />
              <h2 class="taleggio mb-5">
                Владеющая организация
              </h2>
              <r-input
                class="flex-1 float custom mb-5"
                value=""
                v-model="items['owner']"
                label="Название"
                :params-input="{&quot;type&quot;:&quot;input&quot;}"
              />
              <r-input
                class="flex-1 float custom mb-5"
                value=""
                v-model="items['orgn']"
                label="ОГРН"
                :params-input="{&quot;type&quot;:&quot;input&quot;}"
              />
              <r-input
                class="flex-1 float custom mb-10 "
                value=""
                v-model="items['owner_address']"
                label="Адрес"
                :params-input="{&quot;type&quot;:&quot;input&quot;}"
              />
              <h2 class="taleggio mb-5">
                Объект капитального строительства территории
              </h2>
              <r-input
                class="flex-1 float custom mb-5"
                value=""
                v-model="items['property_type']"
                label="Тип"
                :params-input="{&quot;type&quot;:&quot;input&quot;}"
              />
              <r-input
                class="flex-1 float custom"
                value=""
                v-model="items['property_address']"
                label="Адрес"
                :params-input="{&quot;type&quot;:&quot;input&quot;}"
              />
              <div
                class="flex-1 float custom delete-pribor"
                @click="DeleteTKO(items)"
              >
                <r-icon
                  icon="delete"
                  fill="fargo"
                  class="icon-margin"
                />
                <div class="tko">
                  Удалить точку сбора ТКО
                </div>
              </div>
            </div>
            <div v-show="observation">
              <!--             <span class="flex sulguni camera" @click="videoCam" style="color: #3d75e4;cursor: pointer;">-->
              <!--              <rir-icon class="mr-2" icon="add"/>Добавить камеру-->
              <!--            </span>-->
              <!-- <div class="flex align-center">
                <r-select
                  v-model="selectCamera"
                  return-object
                  class="flex-1"
                  :items="cameraList"
                  id-value="id"
                  text-value="name"
                  label="Камеры"
                />
                <r-button-simple
                  size="larishae"
                  type="secondary"
                  color="rocky"
                  :rounded="false"
                  icon="add"
                  class="ml-2"
                  @click="addCamera"
                  :disabled="!selectCamera"
                />
              </div>
              <div
                class=" mt-6"
                style="padding-bottom: 1px;"
                v-if="items.camera_object"
                v-for="item in items['camera_object']"
              >
                <router-link
                  class="mr-4"
                  :to="{
                    name: 'camera',
                    params: { id: `${item.id}` },
                    query:{ id: `${$route.params.id}` }
                  }"
                >
                  <div
                    class="cameraAdd mr-4"
                    style="float: left"
                  >
                    {{ 'Камера ' + item.name }}
                  </div>
                </router-link>
                <div
                  class="tooltip"
                  style="margin-top: 2px;width: 32px;height: 32px;float: left;"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="17"
                    height="17"
                    viewBox="0 0 17 17"
                    fill="none"
                    style="float: left;cursor: pointer;"
                    @click="deleteItem(item.id)"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M13.0009 4.68422C11.1691 1.92281 7.44564 1.16915 4.68422 3.00088C1.92281 4.83261 1.16915 8.5561 3.00088 11.3175C4.83261 14.0789 8.5561 14.8326 11.3175 13.0009C11.7777 12.6956 12.3983 12.8212 12.7036 13.2814C13.0089 13.7416 12.8833 14.3622 12.4231 14.6675C8.74117 17.1098 3.77653 16.1049 1.33422 12.4231C-1.10809 8.74117 -0.103215 3.77653 3.57867 1.33422C7.26056 -1.10809 12.2252 -0.103215 14.6675 3.57867C16.1705 5.84449 16.367 8.59632 15.4406 10.9437C15.255 11.414 15.0242 11.8685 14.7496 12.3001C14.4531 12.766 13.835 12.9034 13.369 12.6069C12.9031 12.3103 12.7657 11.6923 13.0622 11.2263C13.2679 10.9032 13.4409 10.5625 13.5803 10.2095C14.277 8.44409 14.1273 6.38235 13.0009 4.68422Z"
                      fill="#D06E0B"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M11.2072 4.79361C11.5978 5.18413 11.5978 5.8173 11.2072 6.20782L6.20723 11.2078C5.8167 11.5983 5.18354 11.5983 4.79302 11.2078C4.40249 10.8173 4.40249 10.1841 4.79302 9.79361L9.79302 4.79361C10.1835 4.40309 10.8167 4.40309 11.2072 4.79361Z"
                      fill="#D06E0B"
                    />
                  </svg>
                  <span class="tooltiptext">Отвязать</span>
                </div>
              </div>
              -->
            </div>
            <!--
           <div v-show="sensor">
             <div @click="SearchSensor" style="cursor: pointer;">
               <rir-icon icon="search" fill="rocky" class="float mr-2"/>
               <div class="search">
                 Найти ближайшие датчики
               </div>
             </div>
             <h2 class="taleggio mt-5">Датчик №1234</h2>
             <div class="float-left mr-6 flex sulguni mt-2 mb-5">
               <rir-icon icon="geopoint" fill="titanic" class="anie margin-right"/>
               ул. Маршала Жукова, д. 12
             </div>
             <div class="title-conteiner">
               <div class="title-params">Заполнение</div>
               <div class="title-params-item flex sulguni">37%</div>
             </div>
             <div class="title-conteiner flex sulguni">
               <div class="title-params">Статус</div>
               <div class="title-params-item flex sulguni">Прочитано</div>
             </div>
             <div class="title-conteiner flex sulguni">
               <div class="title-params">Дата и время последнего запроса</div>
               <div class="title-params-item flex sulguni">22 октября в 16:39</div>
             </div>
             <div class="float-left mr-6 lebowskis mt-8">
               <rir-icon icon="block" class="float-left block mr-1" fill="lebowski"/>
               <div class="float-left mr-6">Отключить</div>
               <rir-icon icon="delete" fill="fargo" class="icon-margin ml-5"
                         style="padding-top: 4px"/>
               <div class="tko pl-2 delete-sensor"
                    @click="DeleteSensor()">
                 Удалить из системы
               </div>
             </div>
           </div>-->
          </div>
          <div class="ml-6 flex-1 overflow-hidden">
            <div
              class="work-form__map"
              :key="count"
            >
              <rir-map
                :center="coordinates"
                collapse-btn
              >
                <ymap-marker
                  :coords="coordinates"
                  :options="{
                    hideIconOnBalloonOpen: false,
                    draggable: true
                  }"
                  :icon="items?.camera_object?.length > 0 ?
                      getMapIcons(items.type.id).markerIconCam :
                      getMapIcons(items.type.id).markerIcon"
                  @dragend="onDragMarker"
                >
                </ymap-marker>
                <ymap-marker
                  v-if="sensor"
                  :coords="[47.521196, 42.180914]"
                  :marker-id="'marker'"
                  :options="{
                    hideIconOnBalloonOpen: false,
                    draggable: true
                  }"
                  :icon="$sensor"
                  @dragend="onDragMarkerCamera"
                />
<!--
                <ymap-marker
                  v-for="c in cameraMark"
                  :key="c.id"
                  :coords="[c.lat, c.lng]"
                  marker-id="camera"
                  :options="{
                    hideIconOnBalloonOpen: false,
                    draggable: false,
                  }"
                  :icon="{
                    imageHref: c.icon,
                    layout: 'default#image'
                  }"
                /> -->
              <!-- <ymap-marker
                v-if="observation"
                :coords="[47.521196, 42.180914]"
                :marker-id="'marker'"
                :options="{
                hideIconOnBalloonOpen: false,
                draggable: true
                }"
                :icon="$camera"
              >
              </ymap-marker> -->
              </rir-map>
              <!-- <div class="message" v-if="information">
              Чтобы изменить положение на карте, перетащите точку
            </div>-->
              <div
                class="float-left mt-4"
                style="width: 100%;"
              >
                <r-input
                  v-if="information"
                  v-model="items['address']"
                  value=""
                  label="Адрес"
                  :params-input="{&quot;type&quot;:&quot;input&quot;}"
                  @keyup="onCoordinates(items['address'])"
                >
                  <template #after>
                    <r-icon
                      icon="search"
                      fill="metropolis"
                      class="icon-margin"
                    />
                  </template>
                </r-input>
              </div>
            </div>
          </div>
        </div>
        <div v-show="observation" />
        <div class="button_container">
          <r-button
            class="flex-1"
            :disabled="isSave"
            @click="OpenSaveModal"
            width="wide"
            title="Сохранить"
          />
        </div>
      </div>
    </div>
    <search-sensor ref="SearchSensor" />
    <delete-sensor ref="DeleteSensor" />
    <images ref="Images" />
    <archive ref="Archive" />
    <archive-search ref="ArchiveSearch" />
    <r-modal
      ref="modal"
      close-icon
      fullscreen
    />
  </div>
</template>

<script>
import Vue from 'vue';
import vClickOutside from 'v-click-outside';
import RirMap from '@/components/RirMap.vue';
import Select from '@/components/Select.vue';
import SelectType from '@/components/SelectType.vue';
import DeleteSensor from '@/components/DeleteSensor.vue';
import SearchSensor from '@/components/SearchSensor.vue';
import Images from '@/components/Image.vue';
import Archive from '@/components/Archive.vue';
import ArchiveSearch from '@/components/ArchiveSearch.vue';
import Video from '@/components/Video.vue';
import axios from 'axios';
import RirSelect from '@/components/SelectType';
import ConfirmModal from '@/components/ConfirmModal';
import _ from 'lodash';
import Loader from '@/components/Loader.vue';
import DeleteModal from '../components/DeleteModal';
import SaveModal from '../components/SaveModal';
import BalloonCard from '@/components/BalloonCard.vue';

Vue.use(vClickOutside);

export default {
  components: {
    BalloonCard,
    Loader,
    RirSelect,
    RirMap,
    Select,
    SelectType,
    DeleteSensor,
    SearchSensor,
    Images,
    Archive,
    ArchiveSearch,
    Video
  },
  props: {},
  data() {
    return {
      coordinatesCamera: [],
      isActive: Boolean,
      items: {
        lan: '',
        lng: '',
        schedule: '',
        type: 0,
        address: '',
        amount: '',
        coverage: '',
        fence: '',
        extraData: 0,
        square: '',
        owner: '',
        orgn: '',
        volume: '',
        owner_address: '',
        property_type: '',
        property_address: '',
        extraData: {
          garbageType: '',
          clock: '',
          cub: '',
          days: '',
          another: ''
        }
      },
      isLoad: false,
      coordinates: [],
      isButton: true,
      isCovid: Boolean,
      value: '',
      coors: false,
      address: null,
      information: true,
      observation: false,
      sensor: false,
      time: false,
      timeTo: '0:00',
      timeFrom: '0:00',
      changeTime: [],
      count: 0,
      countInput: 0,
      countValue: 0,
      itemsCount: null,
      isCheck: false,
      typeName: '',
      sort: [
        {
          title: 'Контейнер',
          id: 1,
          icon: 'tko'
        },
        {
          title: 'Площадка ТКО',
          id: 2,
          icon: 'tko-place'
        },
        {
          title: 'Бункер-накопитель',
          id: 3,
          icon: 'waste-bin'
        },
        {
          title: 'Мусорокамера',
          id: 4,
          icon: 'waste-camera'
        },
        {
          title: 'Раздельный сбор',
          id: 5,
          icon: 'separate'
        }
      ],
      sortType: [
        {
          title: 'Бумага',
          id: 1
        },
        {
          title: 'Стекло',
          id: 2
        },
        {
          title: 'Металл',
          id: 3
        },
        {
          title: 'PET пластик',
          id: 4
        },
        {
          title: 'Другое',
          id: 5
        }
      ],
      sortDay: [
        {
          title: 'Пн',
          id: 1
        },
        {
          title: 'Вт',
          id: 2
        },
        {
          title: 'Ср',
          id: 3
        },
        {
          title: 'Чт',
          id: 4
        },
        {
          title: 'Пт',
          id: 5
        },
        {
          title: 'Сб',
          id: 6
        },
        {
          title: 'Вс',
          id: 7
        }
      ],
      modelDays: [],
      idTypes: [],
      idType: 0,
      loadVideo: [],
      model: [],
      selectCamera: null,
      svgIcon: null,
      initialData: null,
      isSave: true,
      flyButton: false,
      camera: []
    };
  },
  computed: {
    // пред загрузка
    isLoading() {
      return this.$store.state.isObjectLoadingId;
    },
    cameraList() {
      const _this = this;
      const idCamers = this.items.camera_object ? this.items?.camera_object.map(el => el.id) : [];
      if (_this.loadVideo?.length == 0) {
        return [];
      }
      const video = [];
      _this.loadVideo.forEach(item => {
        video.push({
          ...item,
          title: item.name
        });
      });
      return video.filter(el => !idCamers.includes(el.id));
    },
    cameraMark() {
      if (!this.items.camera_object) return [];
      return this.items.camera_object.reduce((acc, el) => {
        if (el.lat) {
          acc.push({
            ...el,
            icon: this.renderIcon(el)
          });
        }
        return acc;
      }, []);
    }
  },
  watch: {
    items: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.isSave = _.isEqual(newValue, this.initialData);
      }
    }
  },
  created() {
    const _this = this;
    this.$store.dispatch('loadId', this.$route.params.id).then(result => {
      const keys = Object.keys(this.$store.state.loadId.all[0]);
      this.coordinates = [this.$store.state.loadId.all[0].lat, this.$store.state.loadId.all[0].lng];
      // console.log(this.coordinates);
      this.$set(this.items, 'camera_object', []);
      keys.forEach(key => {
        this.$set(this.items, key, this.$store.state.loadId.all[0][key]);
        // this.items[key] = this.$store.state.loadId.all[0][key];
      });
      if (this.items.extraData == null) {
        this.items.extraData = {
          garbageType: '',
          clock: '',
          cub: '',
          days: '',
          another: ''
        };
      } else if (this.items?.schedule != '') {
        const days = this.items.extraData.days.split(',');
        this.sortDay.map(item => {
          days.map(value => {
            if (item.title == value) {
              _this.modelDays.push(item);
            }
          });
        });
        const model = this.items.extraData.garbageType.split(',');
        this.sortType.map(item => {
          model.map(value => {
            if (item.title == value) {
              _this.model.push(item);
            }
          });
        });
        const garbageItem = this.items.schedule.split(' - ');
        this.timeTo = garbageItem[0];
        this.timeFrom = garbageItem[1];
      }
      this.sort = [
        {
          title: 'Контейнер',
          id: 1,
          icon: 'tko'
        },
        {
          title: 'Площадка ТКО',
          id: 2,
          icon: 'tko-place'
        },
        {
          title: 'Бункер-накопитель',
          id: 3,
          icon: 'waste-bin'
        },
        {
          title: 'Мусорокамера',
          id: 4,
          icon: 'waste-camera'
        },
        {
          title: 'Раздельный сбор',
          id: 5,
          icon: 'separate'
        }
      ];
      this.itemsCount = this.items.amount;
      if (this.items.fence != '' || this.items.square != '') {
        this.isCheck = true;
      }
      this.typeName = this.sort[Number(this.items.type) - 1].title;
      this.countInput++;
      this.count++;
      this.items.type = this.sort[Number(this.items.type) - 1];
      this.idType = Number(this.items.type.id);
      this.initialData = JSON.parse(JSON.stringify(this.items));
    }, error => {
      console.error(error);
    });
    this.$store.dispatch('loadVideo').then(result => {
      axios.get(this.$camera.imageHref)
        .then(res => {
          const dom = new DOMParser().parseFromString(res.data, 'image/svg+xml');
          _this.svgIcon = dom.querySelector('svg');
          _this.loadVideo = result;
          if (_this.items.camera_object) {
            _this.camera = [];
            _this.loadVideo.forEach(camera => {
              const cameraId = _this.items.camera_object.filter(el => el.id == camera.id);
              console.log(cameraId);
              if (cameraId?.length > 0) {
                const { guid, name, id } = cameraId[0];
                _this.camera.push({
                  guid,
                  id,
                  name
                });
              }
            });
            _this.items.camera_object = _this.camera;

            console.log(_this.items.camera_object);
          }
        });
      // console.log('eue', v.loadVideo);
      // v.loadVideo.forEach(item => {
      //   v.valIt(item.guid, item.id, item.name);
      // });
    });
  },
  updated() {
    if (this.$refs.content.offsetHeight > window.innerHeight) {
      this.flyButton = true;
    } else {
      this.flyButton = false;
    }
  },
  methods: {
    getMapIcons(type, color = '4480F3') {
      const icons = {
        1: {
          markerIconCam: this.$markerIconCam,
          markerIcon: this.$markerIcon('markerIcon', color)
        },
        2: {
          markerIconCam: this.$tkoCam,
          markerIcon: this.$markerIcon('tko', color)
        },
        3: {
          markerIconCam: this.$wasteBinCam,
          markerIcon: this.$markerIcon('wasteBin', color)
        },
        4: {
          markerIconCam: this.$wasteCamCam,
          markerIcon: this.$markerIcon('wasteCam', color)
        },
        5: {
          markerIconCam: this.$separateCam,
          markerIcon: this.$markerIcon('separate', color)
        }
      };
      return icons[type] || {
        markerIcon: this.$markerIcon()
      };
    },
    linkAdmin() {
      const r = this.$router.resolve({
        name: 'index'
      });

      window.location.assign(r.href);
    },
    async OpenSaveModal() {
      await this.$refs.modal.openModal(SaveModal, {
        saveFunc: this.save,
        title: this.items.address,
        items: this.items
      });
    },
    funcCheck() {
      this.isCheck = !this.isCheck;
      if (this.isCheck == false) {
        this.items.fence = '';
        this.items.square = '';
      }
    },
    informationFunc() {
      this.information = true;
      this.observation = false;
      this.sensor = false;
      this.count++;
    },
    observationFunc() {
      this.information = false;
      this.observation = true;
      this.sensor = false;
      this.count++;
    },
    sensorFunc() {
      this.information = false;
      this.observation = false;
      this.sensor = true;
      this.count++;
    },
    onClickOutside(event) {
      this.time = false;
    },
    videoCam() {
      this.$refs.Video.showVideo();
    },
    async DeleteTKO(item) {
      await this.$refs.modal.openModal(DeleteModal, {
        item
      });
    },
    DeleteSensor() {
      this.$refs.DeleteSensor.showSensor();
    },
    SearchSensor() {
      this.$refs.SearchSensor.showSensor();
    },
    Images() {
      this.$refs.Images.showImage();
    },
    Archive() {
      this.$refs.Archive.showArchive();
    },
    ArchiveSearch() {
      this.$refs.ArchiveSearch.showArchive();
    },
    hourAdd(item) {
      for (let i = 1; i <= 24; i++) {
        const hour = document.querySelectorAll(`div.hour${i}`)[0];
        if (item == i) {
          hour.style.background = '#3D75E4';
          hour.style.color = '#FFFFFF';
          hour.style.borderRadius = '5px';
        } else {
          hour.style.background = '';
          hour.style.color = '';
        }
      }
    },
    minutesAdd(item) {
      for (let i = 1; i <= 24; i++) {
        const minutes = document.querySelectorAll(`div.minutes${i}`)[0];
        if (item == i) {
          minutes.style.background = '#3D75E4';
          minutes.style.color = '#FFFFFF';
          minutes.style.borderRadius = '5px';
        } else {
          minutes.style.background = '';
          minutes.style.color = '';
        }
      }
    },
    async deleteItem(id) {
      const res = await this.$refs.modal.openModal(ConfirmModal, {
        title: 'Отвязать камеру',
        subTitle: 'Камера «Парк Горького, южный вход» будет отвязана от точки ТКО',
        titleBtnConfirm: 'Да, отвязать',
        titleBtnReset: 'Не отвязывать',
        id,
        saveFunc: this.emitClose
      });
    },
    emitClose(id) {
      // console.log(res)
      this.items.camera_object = this.items.camera_object.filter(el => el.id !== id);
      // var v = this;
      // this.items['camera_object'].forEach(function (value, itemKey) {
      //   if (value.id == id) {
      //     var it = v.items['camera_object'].filter(
      //         el => (el.id != id)
      //     );
      //     var item = v.$store.state.loadId.all[0];
      //     item.camera_object = it;
      //     v.$store.dispatch('loadSave', item).then(result => {
      //       let r = v.$router.resolve({
      //         name: 'edit',
      //         params: {id: v.$store.state.loadId.all[0].id},
      //       });
      //       window.location.assign(r.href)
      //     });
      //   }
      // });
    },
    addCamera() {
      console.log(this.selectCamera)
      if(!this.selectCamera) {
        return;
      }
      const { guid, name, id, lat, lng, angle } = this.selectCamera;
      if (this.items.camera_object == null) {
        this.items.camera_object = [];
      }
      this.items.camera_object.push({
        guid,
        id,
        lat,
        lng,
        name,
        angle
      });
      this.selectCamera = null;
    },

    save(array) {
      console.log(this.idType);
      console.log('  save(array)', array);
      if (Number(this.idType) >= 1) {
        this.$store.state.isObjectLoadingId = true;
        const item = this.$store.state.loadId.all[0];

        let type = '';
        this.idTypes.map(item => {
          type += `,${item.title}`;
        });
        if (type != '') {
          type = type.substr(1);
        }

        let days = '';
        this.modelDays.map(item => {
          days += `,${item.title}`;
        });
        if (days != '') {
          days = days.substr(1);
        }

        array.extraData.garbageType = type;
        array.extraData.days = days;
        item.schedule = array.schedule ? array.schedule : `${this.timeTo} - ${this.timeFrom}`;
        item.lat = this.coordinates[0];
        item.lng = this.coordinates[1];
        item.volume = array.volume;
        item.type = this.idType;
        item.address = array.address;
        item.amount = array.amount;
        item.coverage = array.coverage;
        if (this.isCheck) {
          item.fence = array.fence;
        } else {
          item.fence = '';
        }
        item.extraData = array.extraData;
        item.square = array.square;
        item.owner = array.owner;
        item.orgn = array.orgn;
        item.owner_address = array.owner_address;
        item.property_type = array.property_type;
        item.property_address = array.property_address;
        item.camera_object = array.camera_object;
        // console.log(item)
        this.$store.dispatch('loadSave', item).then(result => {
          const r = this.$router.resolve({
            name: 'edit',
            params: { id: this.$store.state.loadId.all[0].id }
          });
          if (!result?.error) {
            window.location.assign(r.href);
          }
        });
      }
    },
    chengeType(id) {
      this.idType = id.id;
      console.log(this.idType);
      this.countValue++;
    },
    chenge(val) {
      this.idTypes = val;
    },
    chengeSchedule() {
      this.items.schedule = `${this.timeTo} - ${this.timeFrom}`;
    },
    chengeDay(val) {
    },
    onDragMarkerCamera(e) {
      this.coordinatesCamera = e.get('target').geometry.getCoordinates();
    },
    onDragMarker(e) {
      this.coordinates = e.get('target').geometry.getCoordinates();
      axios.get(`https://geocode-maps.yandex.ru/1.x/?apikey=f8217e42-9b86-4033-8411-a7bf4f8d6835&geocode=${this.coordinates[1]},${this.coordinates[0]}&format=json&lang=ru_RU&kind=house`)
        .then(result => {
          const geo = result.data.response.GeoObjectCollection.featureMember?.[0];

          if (geo) {
            this.items.address = geo.GeoObject.name;
          }
        });
    },
    onCoordinates(item) {
      axios.get(`https://geocode-maps.yandex.ru/1.x/?apikey=f8217e42-9b86-4033-8411-a7bf4f8d6835&geocode=Волгодонск${item.trim()}&format=json&lang=ru_RU&kind=house`)
        .then(result => {
          const geo = result.data.response.GeoObjectCollection.featureMember?.[0]?.GeoObject;

          if (geo) {
            this.coordinates = geo.Point?.pos?.split(' ').reverse();
            // console.log(this.items['address'])
          }
        });
    },
    renderIcon(item) {
      this.svgIcon.style.transform = `rotate(${item.angle}deg)`;
      return `data:image/svg+xml;base64,${btoa(this.svgIcon.outerHTML)}`;
    }
  }
};
</script>

<style lang="scss" scoped>
.button_container {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 2;
  width: 100%;
  max-width: 74.7%;
  background: #FFFFFF;
  box-shadow: 0px 8px 32px rgb(4 21 62 / 16%);
  padding-left: 31px;
  padding-right: 40px;
  padding-bottom: 40px;
  height: 100px;
  padding-top: 28px;
}
.button_fly{
  padding-bottom: 112px;
  .button_container{
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 2;
    width: 100%;
    max-width: calc(100% - 25.3%);
    background: #FFFFFF;
    box-shadow: 0px 8px 32px rgba(4, 21, 62, 0.16);
    padding-left: 31px;
    padding-right: 40px;
    padding-bottom: 40px;
    height: 100px;
    padding-top: 28px;
  }
}
.tooltip .tooltiptext {
  margin-top: 30px;
  margin-left: -15px;
  visibility: hidden;
  width: 76px;
  height: 30px;
  background: #FFFFFF;
  border-radius: 12px;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: #04153E;
  padding: 8px 8px 8px 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);

  /* Разместите всплывающую подсказку */
  position: absolute;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.camera {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: right;
  color: #3D75E4;
}

.margin-class {
  margin-top: -1px;
}

.width-class {
  width: 100%;
}

.search-class {
  position: absolute;
  padding-top: 14px;
  padding-left: 28px
}

.delete-sensor {
  padding-top: 4px;
  color: #E14761;
  cursor: pointer;
}

.time-z {
  width: 100%;
  z-index: 1400;
}

.margin-bottom {
  margin-bottom: 40px;
}

.h-tko {
  overflow: auto;
}

.icon-search-div {
  width: 76px;
  height: 48px;
  opacity: 0;
  position: absolute;
  cursor: pointer;

  &:hover {
    background: #04153E;
    opacity: 0.48;
    border-radius: 8px;
  }
}

.obj-card {
  position: relative;
  display: flex;
  background-color: #fff;
  border: 1px solid #e4edfb;
  padding: 16px 22px 16px 16px;
  border-radius: 16px;

  &__img {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    background-color: var(--rir-arrival);
    margin-right: 12px;
    width: 72px;
    height: 72px;
    border-radius: 8px;

    > img {
      height: 100%;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    width: 50%;
    float: left;
  }

  &__title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__address {
    opacity: 0.72;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__counters {
    margin-top: 1px;
  }

  &__restore {
    background-color: transparent;
    cursor: pointer;
  }
}

.pointer {
  cursor: pointer;
}

.align-center {
  //overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  position: static;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: #04153E;
}

.float {
  float: left;
}

.time {
  float: left;

  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: #04153E;
  opacity: 0.48;
}

.img {
  float: left;
  width: 16px;
  height: 16px;
  margin-bottom: 10px;
}

.grid-cont {
  grid-column-gap: 24px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
}

.archive {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  width: 20%;
  color: #3D75E4;
  cursor: pointer;
}

.edit {
  &__map {
    height: 522px;
    overflow: hidden;
  }
}

.search {
  color: #3D75E4;
}

.margin-info {
  margin-top: 24px;
  width: 100%;
}

.text-covid {
  display: table;
  float: right;
  width: calc(100% - 174px)
}

::v-deep .rir-file-list__details {
  padding: 6px;
}

::v-deep .rir-file-list__item .rir-file-list__img {
  min-width: 76px;
  width: 76px;
  height: 100%;
}

::v-deep .rir-file-list__item.mini .rir-file-list__info {
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
}

::v-deep .rir-file-list__info {
  color: var(--rir-titanic);
  margin-bottom: 0;
}

::v-deep .rir-file-list__size {
  font-size: 13px;
  line-height: 16px;
  color: var(--rir-rocky);
}

::v-deep .rir-drag-upload-file__remove {
  & svg {
    & path {
      fill: var(--rir-fargo);
    }
  }
}

::v-deep .rir-popover__content .rir-select__content {
  border-radius: 8px;
}

@mixin reset-list {
  font-style: normal;
  font-weight: normal;
  position: static;
}

.title-params-item {
  @include reset-list;
  left: 35%;
  font-size: 16px;
  line-height: 20px;
  color: #04153E;
  display: table;
}

.float-left {
  float: left;
}

.street-house {
  margin-top: 13px;
  @include reset-list;
  width: 100%;
  height: 30px;
  left: 24px;
  font-size: 16px;
  line-height: 20px;
  color: #04153E;
}

.street {
}

.street:hover {
  opacity: 1;
}

.print {
  fill: #3D75E4;
  color: #3D75E4;
}

.pointer {
  cursor: pointer;
}

.eye {
  margin-top: 1px;
}

.block {
  fill: #E14761;
  color: #E14761;
  margin-top: 1px;
}

.message {
  @include reset-list;
  width: 361px;
  height: 16px;
  left: 820px;
  top: 621px;
  font-size: 13px;
  line-height: 16px;
  color: #000000;
  margin-top: 5px;
}

.block-hiden-one {
  margin-top: 40px;
}

.margin-right {
  margin-right: 12px;
}

.icon-margin {
  position: absolute;
  margin-top: -2px;
  margin-left: -10px;
  color: #E5E5E5;
}

.line {
  position: static;
  height: 4px;
  background: #3D75E4;
  border-radius: 2px;
  margin: 8px 0px;
}

.link-information {
  cursor: pointer;
  display: table;
}

.float {
  float: left;
}

.header-link {
  margin-top: 21px;
  padding-bottom: 32px;
}

.time-days {
  width: calc(50% - 10px);
  z-index: 13;
}

.rir-input__after {
  max-height: 200px;
}

.custom {
  width: 100%;
}

.opacity {
  opacity: 1;
  border-radius: 8px;
}

.hours {
  display: table;
  background: #FFFFFF;
  box-shadow: 0px 9px 28px rgba(17, 48, 121, 0.18);
  border-radius: 8px;
  margin-top: 60px;
  z-index: 1200;
  position: relative;
  width: 100%;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #04153E;
  text-align: center;
  height: 160px;

  .hour {
    margin-top: 5px;
    max-height: 150px;
  }

  .minutes {
    margin-top: 5px;
    max-height: 150px;
  }

  .hour::-webkit-scrollbar-track {
    width: 4px;
    background: #FFFFFF;
  }

  .hour::-webkit-scrollbar {
    width: 4px;
    background: #e4edfb;
    border-radius: 2px;
    opacity: 0.72;
  }

  .hour::-webkit-scrollbar-thumb {
    width: 4px;
    background: #e4edfb;
    border-radius: 2px;
    opacity: 0.72;
  }

  .minutes::-webkit-scrollbar-track {
    width: 4px;
    background: #FFFFFF;
  }

  .minutes::-webkit-scrollbar {
    width: 4px;
    background: #e4edfb;
    border-radius: 2px;
    opacity: 0.40;
  }

  .minutes::-webkit-scrollbar-thumb {
    width: 4px;
    background: #e4edfb;
    border-radius: 2px;
    opacity: 0.40;
  }

  .time-hover {
    width: 60px;
    margin: auto;
    margin-top: 6px;
  }

  .time-hover:hover {
    width: 60px;
    background: #e4edfb;
    margin: auto;
    color: #3D75E4;
    border-radius: 5px;
    margin-top: 6px;
  }
}

.rir-list > .rir-list-item * {
  opacity: 1;
}

.delete-pribor {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #E14761;
  margin-left: 12px;
  margin-top: 32px;
  margin-bottom: 60px;
  cursor: pointer;
}

.tko {
  display: table;
  margin-left: 20px;
  margin-top: -3px;
}

@mixin reset-list {
  font-style: normal;
  font-weight: normal;
  position: static;
}

.title-conteiner {
  padding-bottom: 16px;
  width: 100%;
  display: table;
}

.title-params {
  @include reset-list;
  right: 69.8%;
  top: 10%;
  bottom: 10%;
  font-size: 13px;
  line-height: 16px;
  color: #04153E;
  opacity: 0.48;
  float: left;
  width: 151px;
  max-width: 151px;
  margin-right: 24px;
}

.lebowskis {
  color: #D06E0B;
}

.other-icon:hover::after {
  content: attr(data-title); /* Выводим текст */
  position: absolute; /* Абсолютное позиционирование */
  left: -1400%;
  top: 130%; /* Положение подсказки */
  z-index: 1; /* Отображаем подсказку поверх других элементов */
  width: 250px;
  height: 80px;
  background: #FFFFFF;
  border-radius: 12px;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: #04153E;
  padding: 8px 8px 8px 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.cameraAdd {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #04153E;
}
.work-form__map {
  height: 522px;
  overflow: hidden;
}
</style>
<style>
.rir-list>.rir-list-item * {
  opacity: 1 !important;
}
.rir-input.readonly:not(.rir-select) {
  opacity: 1 !important;
}
.fargo {
  background-color: unset;
}
</style>
