<template>
  <r-popover
    :disabled="readonly"
    content-class="rir-select__content rir-popover__radius"
    v-model="showSelect"
  >
    <template #activator="{ on }">
      <r-input
        readonly
        :max-width="maxWidth"
        :placeholder="placeholder"
        :label="label"
        :value="model"
        @click.native="on.click"
        class="rir-select "
        :class="{
          disabled: readonly
        }"
      >
        <a
          class="rir-select__icon"
          ref="icon"
          slot="after"
          tabindex="0"
          @keyup.tab="changeViewList"
          @keydown.tab="changeViewList"
        >
          <r-icon
            :size="20"
            :class="{
              active: showSelect
            }"
            icon="arrow-down"
          />
        </a>
      </r-input>
    </template>
    <r-list
      overflow
      v-if="itemsList.length"
    >
      <r-list-item
        @click.native="!item.disabled && selectItem(item)"
        v-for="item in itemsList"
        :class="{
          active: item[idValue] === modelId,
          disabled: item.disabled
        }"
        :key="item.forUuId"
      >
        <template
          v-if="!isSlotItem"
          style="min-height: 320px;"
        >
          <r-icon
            class="mr-2"
            style="float: left"
            icon="tko"
            size="16"
            v-if="item['icon'] == 1"
          />
          <r-icon
            class="mr-2"
            style="float: left"
            icon="tko-place"
            size="16"
            v-if="item['icon'] == 2"
          />
          <r-icon
            class="mr-2"
            style="float: left"
            icon="waste-bin"
            size="16"
            v-if="item['icon'] == 3"
          />
          <r-icon
            class="mr-2"
            style="float: left"
            icon="waste-camera"
            size="16"
            v-if="item['icon'] == 4"
          />
          <r-icon
            class="mr-2"
            style="float: left"
            icon="separate"
            size="16"
            v-if="item['icon'] == 5"
          />
          <div style="float: left">
            {{ item[textValue] }}
          </div>
        </template>
        <slot
          name="item"
          :item="item"
        />
      </r-list-item>
    </r-list>
    <div
      class="rir-select__no-date"
      v-else
    >
      {{ noDataItems }}
    </div>
  </r-popover>
</template>

<script>

export default {
  name: 'RirSelect',
  props: {
    value: {
      validator: prop => ['string', 'object', 'number'].includes(typeof prop) || prop === null,
      default: () => ({})
    },
    returnObject: {
      type: Boolean
    },
    valueName: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    maxWidth: {
      type: String,
      default: '100%'
    },
    items: {
      type: Array,
      default: () => ([])
    },
    textValue: {
      type: String,
      default: 'value'
    },
    idValue: {
      type: String,
      default: 'id'
    },
    noDataItems: {
      type: String,
      default: 'Список пустой'
    },
    selectAll: {
      type: Boolean
    },
    readonly: {
      type: Boolean
    },
    selectFirstItem: {
      type: Boolean
    }
  },
  data: () => ({
    showSelect: false,
    item: null
  }),
  computed: {
    model() {
      // console.log(this.item);
      return this.item
        ? this.item[this.textValue]
        : null;
    },
    modelId() {
      return this.item
        ? this.item[this.idValue]
        : null;
    },
    itemsList() {
      const items = this.items.map(el => ({
        ...el,
        forUuId: this.uuIdv4()
      }));
      if (this.selectAll) {
        items.unshift({
          [this.idValue]: null,
          [this.textValue]: 'Все',
          forUuId: this.uuIdv4()
        });
      }
      return items;
    },
    isSlotItem() {
      return !!this.$slots.item || !!this.$scopedSlots.item;
    }
  },
  watch: {
    value(val) {
      this.item = this.value ? this.itemsList.find(el => el[this.idValue] === (this.returnObject ? this.value[this.idValue] : this.value)) || null : null;
      // this.item = this.itemsList.find(el =>
      //   el[this.idValue] === (this.returnObject ? val[this.idValue] : val))
    }
  },
  mounted() {
    if (this.selectFirstItem) {
      this.item = this.items[0];
    }
    this.item = this.value ? this.itemsList.find(el => el[this.idValue] === (this.returnObject ? this.value[this.idValue] : this.value)) || null : null;
  },
  methods: {
    changeViewList() {
      this.showSelect = !this.readonly && !this.showSelect;
    },
    selectItem(item) {
      this.item = { ...item };
      delete item.forUuId;
      const val = this.returnObject ? item : item[this.idValue];
      this.$emit('change', item);
      this.$emit('input', val);
      this.showSelect = false;
    }
  }
};
</script>
