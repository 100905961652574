<template>
  <div>
    <r-textarea
      class="mt-6 mb-6"
      :value="message"
      label="Содержание файла"
      :rows="8"
    />
    <section class="rir-drag-upload-file__body">
      <div
        class="rir-drag-upload-file__uploading"
        v-show="isUpload"
      >
        <span>Загрузка...</span>
        <div
          :style="{
            width: `${progress}%`
          }"
          class="rir-drag-upload-file__uploading--progress"
        />
      </div>
      <label
        v-show="!isUpload"
        ref="upload"
        class="rir-drag-upload-file"
        :class="{
          'rir-drag-upload-file--max-files': disabledUpload
        }"
        :for="getUuId"
      >
        <div class="rir-drag-upload-file__content">
          <div>
            <r-icon :icon="icon" />
            <span v-if="title">{{ title }}</span>
          </div>
          <span
            v-if="disabledUpload"
          >Вы уже загрузили максимум файлов</span>
          <span v-else>{{ getTextCountFiles }}</span>
        </div>
        <input
          :disabled="disabledUpload"
          ref="input"
          :accept="acceptingFiles"
          :id="getUuId"
          type="file"
          multiple
          @change="load($event.target.files, this)"
        >
      </label>
      <div
        v-if="disabledUpload"
        class="files"
      >
        <div class="files_icon">
          <r-icon
            icon="file"
            size="20"
            class="files_icon-margin"
          />
        </div>
        <div class="files-body">
          <div style="max-width: calc(100% - 30px)">
            <div class="files-name flex text-c16 text-titanic">
              {{ filesLoad[0].name }}
            </div>
            <div class="files-size text-rocky mozzarella">
              {{ fileSize }}
            </div>
          </div>
          <template>
            <r-icon
              icon="delete"
              class="files-delete"
              fill="fargo"
              @click.native="removeFile()"
            />
          </template>
        </div>
      </div>
    </section>
    <r-checkbox
      class="mt-6 mb-6"
      :title="'Удалять все данные перед загрузкой' "
      v-model="delPurgeCheck"
      @input="onChange(delPurge)"
    />
  </div>
</template>

<script>
import Vue from 'vue';
import * as XLSX from 'xlsx';
import ApiService from '../api/ApiService.js';

export default {
  name: 'DragUploadFile',
  props: {
    title: {
      type: String,
      default: 'Выбрать файлы'
    },
    icon: {
      type: String,
      default: 'attach'
    },
    iconFill: {
      type: String,
      default: 'rocky'
    },
    description: {
      type: String,
      default: ''
    },
    value: {
      type: Array
    },
    countFile: {
      type: Number,
      default: 2
    },
    countLine: {
      type: Number,
      default: 2
    },
    listView: {
      type: String,
      default: 'DEFAULT'
    },
    showSize: {
      type: Boolean,
      default: true
    },
    acceptFile: {
      type: Array,
      default: () => ['.jpg', '.jpeg', '.png', '.gif']
    },
    uploadUrl: {
      type: String,
      require: true
    },
    paramsBody: {
      type: Object,
      default: () => ({})
    }
  },
  data: vm => ({
    filesLength: 0,
    filesCountUpload: vm.value.length,
    isUpload: false,
    filesLoad: '',
    disabledUpload: false,
    dataLoad: null,
    base64: null,
    fileSize: '',
    message: '',
    delPurgeCheck: false,
    delPurge: 0
  }),

  computed: {
    acceptingFiles() {
      return this.acceptFile.join(',');
    },
    getUuId() {

    },
    getTextCountFiles() {
      if (this.description) return this.description;
      const text = `или перетяните сюда${
        this.countFile ? ` до ${this.countFile} файлов` : ''
      }`;
      return `${text}, объёмом не более 50 МБайт`;
    },
    progress() {
      return ((this.filesCountUpload / this.filesLength) * 100).toFixed(3);
    },
    apiAxios() {
      return new ApiService();
    }
  },
  mounted() {
    ['dragenter', 'dragover', 'dragleave', 'drop'].forEach(eventName => {
      this.$refs.upload.addEventListener(
        eventName,
        this.preventDefaults,
        false
      );
    });
    ['dragenter', 'dragover'].forEach(eventName => {
      this.$refs.upload.addEventListener(eventName, this.highlight, false);
    });
    ['dragleave', 'drop', 'dragend'].forEach(eventName => {
      this.$refs.upload.addEventListener(eventName, this.unHighlight, false);
    });
    ['drop'].forEach(eventName => {
      this.$refs.upload.addEventListener(eventName, this.handleDrop, false);
    });
  },
  beforeDestroy() {
    ['dragenter', 'dragover', 'dragleave', 'drop'].forEach(eventName => {
      this.$refs.upload.removeEventListener(
        eventName,
        this.preventDefaults,
        false
      );
    });
    ['dragenter', 'dragover'].forEach(eventName => {
      this.$refs.upload.removeEventListener(eventName, this.highlight, false);
    });
    ['dragleave', 'drop', 'dragend'].forEach(eventName => {
      this.$refs.upload.removeEventListener(eventName, this.unHighlight, false);
    });
    ['drop'].forEach(eventName => {
      this.$refs.upload.removeEventListener(eventName, this.handleDrop, false);
    });
  },
  methods: {
    onChange(item) {
      if (item == 0) {
        this.delPurge = 1;
      } else {
        this.delPurge = 0;
      }
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    },
    preventDefaults(e) {
      e.preventDefault();
      e.stopPropagation();
    },
    highlight() {
      this.$refs.upload.classList.add('highlight');
    },
    unHighlight() {
      this.$refs.upload.classList.remove('highlight');
    },
    load(files, inputElement) {
      const _this = this;
      this.filesLoad = files;
      this.getBase64(files[0]);
      if (files[0].size > 1024 * 1024) {
        const size = files[0].size / 1024 / 1024;
        this.fileSize = `${size.toFixed(1)} МБайт`;
      } else {
        const size = files[0].size / 1024;
        this.fileSize = `${size.toFixed(0)} КБайт`;
      }
      _this.message = '';
      console.time();
      const reader = new FileReader();
      reader.onloadend = function (event) {
        const arrayBuffer = reader.result;
        // debugger

        const options = { type: 'array' };
        const workbook = XLSX.read(arrayBuffer, options);
        console.timeEnd();

        const sheetName = workbook.SheetNames;
        const sheet = workbook.Sheets[sheetName];
        const regex = /( |<([^>]+)>)/ig;
        let result = XLSX.utils.sheet_to_html(sheet);
        result = result.match(/v=([\"\'])([^\"\']+)/g);
        result.forEach(element => {
          _this.message += `${element.replace('v="', '')}\n`;
        });
      };
      reader.readAsArrayBuffer(files[0]);

      this.disabledUpload = true;
    },
    handleDrop(e) {
      const dt = e.dataTransfer;
      const { files } = dt;
      this.getBase64(files[0]);
      this.load(files);
    },
    async handleFiles(files) {
      if (this.filesLoad != '') {
        const filesList = this.filesLoad;
        this.getBase64(filesList[0]);
        if (filesList.length) this.isUpload = true;
        this.filesLength = filesList.length;
        await this.uploadFile();
      }
    },
    async uploadFile() {
      const _this = this;
      const re = /(?:\.([^.]+))?$/;
      const fileExt = this.filesLoad;
      _this.filesCountUpload += 1;

      return new Promise(resolve => {
        const url = _this.uploadUrl;
        const formData = new FormData();

        const params = Object.keys(this.paramsBody);
        if (params.length !== 0) {
          params.forEach(key => {
            formData.append(key, _this.paramsBody[key]);
          });
        }
        this.getBase64(fileExt[0]).then(function (responses) {
          _this.disabledUpload = false;

          _this.apiAxios._axios.post(url, { base64file: responses, purge: this.delPurge }, {
            headers: {
              'content-type': 'application/json',
              withCredentials: true,
              'Access-Control-Allow-Credentials': true
            }
          }).then(response => {
            if (response.status !== 200) {
              throw Error(`error load image - ${response.status}`);
            } else {
              return response.data;
            }
          })
            .then(data => {
              if (data.error) {
                _this.$emit('uploadError');
              } else {
                _this.$emit('uploadEvent', data);
              }
              _this.filesLoad = '';
            })
            .finally(() => {
              if (_this.progress >= 100) {
                _this.isUpload = false;
                _this.$refs.input.value = '';
              }
              resolve();
            });
        });
      });
    },
    removeFile() {
      this.disabledUpload = false;
      this.filesLoad = '';
      this.message = '';
    }
  }
};
</script>
<style lang="scss" scoped>
::v-deep .rir-textarea__textarea {
  overflow-y: auto !important;
}
.files-delete {
  cursor: pointer;
  margin-left: 13px;
}

.files {
  margin-top: 24px;
  color: #04153E;
  margin-bottom: 10px;
  height: 48px;
  border-radius: 8px;
  background: rgba(#3D75E4, 0.08);
  display: flex;
  align-items: center;
}

.files_icon {
  height: 100%;
  width: 76px;
  border-radius: 8px;
  background: #E4EDFB;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.files-name {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.files-body {
  height: 100%;
  width: calc(100% - 76px);
  padding-left: 12px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-right: 17px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.rir-drag-upload-file__body {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.rir-drag-upload-file__uploading {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 96px;
  border-radius: 8px;
  overflow: hidden;
}
.rir-drag-upload-file, .rir-file-list__actions {
  display: flex;
  align-items: center;
}
.rir-drag-upload-file {
  width: 100%;
  height: 96px;
  border: 1px dashed #04153e;
  padding: 0 16px;
  border-radius: 8px;
  cursor: pointer;
}
.rir-drag-upload-file__content {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.rir-drag-upload-file>input {
  display: none;
}
.rir-drag-upload-file__content>div {
  color: #04153e;
  display: flex;
  align-items: center;
  margin-bottom: 4px;
}
.rir-drag-upload-file__content>div>span {
  margin-left: 8px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
}
.rir-drag-upload-file__content>span {
  text-align: center;
  color: rgba(4,21,62,.72);
  font-size: 13px;
  line-height: 16px;
}
.files-delete {
  cursor: pointer;
  margin-left: 13px;
}

.files {
  margin-top: 24px;
  color: #04153E;
  margin-bottom: 10px;
  height: 48px;
  border-radius: 8px;
  background: rgba(#3D75E4, 0.08);
  display: flex;
  align-items: center;
}

.files_icon {
  height: 100%;
  width: 76px;
  border-radius: 8px;
  background: #E4EDFB;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.files-name {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.files-body {
  height: 100%;
  width: calc(100% - 76px);
  padding-left: 12px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-right: 17px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
<style>
.rir-drag-upload-file__body {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.rir-drag-upload-file__uploading {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 96px;
  border-radius: 8px;
  overflow: hidden;
}
.rir-drag-upload-file__uploading>span {
  color: rgba(4,21,62,.48);
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
}
.rir-drag-upload-file__uploading--progress {
  transition: width .5s;
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(61,117,228,.16);
}
</style>
