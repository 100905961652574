<template>
  <section>
    <section
      v-if="thumb"
      class="cam-thumb pointer"
      :style="getThumb"
      @click="selectRow"
    />
    <section
      class="cam-thumb"
      v-else
    >
      <r-icon
        icon="video-control"
        size="20"
        fill="alien"
      />
    </section>
  </section>
</template>

<script>
import PlaceApi from '@/api/PlaceApi';
import ModalScreenshot from '@/views/camers/ModalScreenshot';

const api = new PlaceApi();
export default {
  name: 'CamScreenshot',
  props: {
    item: {
      type: Object
    }
  },
  data: () => ({
    thumb: null
  }),
  computed: {
    getThumb() {
      return {
        backgroundImage: `url("${this.thumb}")`
      };
    }
  },
  async mounted() {
    // const { data: status } = await api.getScreenshotSensorCam(`/trassir/rest/scr/${this.item.screenshot.id}/status`)

    if (this.item.screenshot.status === 'COMPLETED') {
      this.thumb = `${process.env.VUE_APP_API_SERVER}/trassir/rest/scr/${this.item.screenshot.id}/thumb`;
    }
  },
  methods: {
    selectRow() {
      if (this.item.screenshot.status === 'COMPLETED') {
        this.$modal(ModalScreenshot, {
          item: this.item
        });
      }
    }
  }
};
</script>

<style lang="scss">
.cam-thumb{
  aspect-ratio: 16 / 10;
  background-color: #E4EDFB;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
