<template>
  <div class="flex">
    <div
      v-show="upload"
      class="upload"
    >
      <div>
        <div @click="upload = !upload">
          <r-icon
            class="mr-2 margin-right"
            icon="close"
            size="20"
          />
        </div>
        <div class="parent align-center">
          <div class="container-upload">
            <h1 class="ricotta mb-2">
              Поиск видеозаписи в архиве
            </h1>
            <div class="mb-2 font">
              Выберите дату и промежуток времени фрагмента
            </div>
            <div class="flex mb-2 hour-minutes">
              <r-date-picker
                class="mt-6 hour-minutes__date"
                click-close
                label="Дата"
              />
              <div
                class="hour-minutes__item time-days mt-1"
                @click="time = true"
                v-click-outside="onClickOutside"
              >
                <r-input
                  class="flex-2 mt-5 float time-resize hour-minutes__input"
                  :value="timeTo + ' - ' + timeFrom"
                  placeholder="Время вызова"
                  :params-input="{&quot;type&quot;:&quot;input&quot;}"
                >
                  <template #after>
                    <r-icon
                      icon="clock"
                      fill="metropolis"
                      class="icon-margin"
                    />
                  </template>
                </r-input>
                <section
                  class="rir-date-picker hours"
                  v-show="time"
                >
                  <div class="rir-date-picker__time">
                    <div class="hour">
                      <div
                        v-for="n in 24"
                        :key="n"
                      >
                        <div
                          :class="`time-hover hourone${n}`"
                          @click="timeTo = `${n-1}`+':00'"
                          @click.prevent="hourAdd(n)"
                        >
                          {{ n - 1 }}:00
                        </div>
                      </div>
                    </div>
                    <div class="minutes">
                      <div
                        v-for="n in 24"
                        :key="n"
                      >
                        <div
                          :class="`time-hover minutesone${n}`"
                          @click="timeFrom = `${n-1}`+':00'"
                          @click.prevent="minutesAdd(n)"
                        >
                          {{ n - 1 }}:00
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
            <div
              class="flex mt-2"
              :style="'width: 100%;' + (time ? 'margin-top: -152px;' : '')"
            >
              <r-button class="flex-1">
                <r-icon
                  icon="load"
                  class="mr-2"
                  size="16"
                  fill="joker"
                />
                Скачать фрагмент
              </r-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Archive',
  data() {
    return {
      upload: false,
      time: false,
      timeTo: '0:00',
      timeFrom: '0:00'
    };
  },
  methods: {
    onClickOutside(event) {
      this.time = false;
    },
    showArchive() {
      this.upload = true;
    },
    hourAdd(item) {
      for (let i = 1; i <= 24; i++) {
        const hour = document.querySelectorAll(`div.hourone${i}`)[0];
        if (item == i) {
          hour.style.background = '#3D75E4';
          hour.style.color = '#FFFFFF';
          hour.style.borderRadius = '5px';
        } else {
          hour.style.background = '';
          hour.style.color = '';
        }
      }
    },
    minutesAdd(item) {
      for (let i = 1; i <= 24; i++) {
        const minutes = document.querySelectorAll(`div.minutesone${i}`)[0];
        if (item == i) {
          minutes.style.background = '#3D75E4';
          minutes.style.color = '#FFFFFF';
          minutes.style.borderRadius = '5px';
        } else {
          minutes.style.background = '';
          minutes.style.color = '';
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.hour-minutes {
  width: 100%;
  display: inline-block;

  &__date {
    width: calc(50% - 12px);
    float: left;
  }

  &__item {
    display: table;
    width: calc(50% - 12px);
    float: right;
  }

  &__input {
    width: 100%;
    z-index: 1400;
  }
}

.align-center {
  align-items: center;
  float: left;
  margin-top: 5px;
}

.upload {
  background-color: white;
  z-index: 999;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}

.parent {
  display: block;
  position: absolute;
  width: 40%;
  height: 400px;
  top: 52%;
  margin-left: 33%;
  margin-top: -150px;
}

.container-upload {
  width: 87%;
  height: 300px;
}

.margin-right {
  float: right;
  margin-top: 17px;
  margin-right: 17px;
  z-index: 1000;
  cursor: pointer;
}

.font {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #04153E;
  opacity: 0.72;
}

.hours {
  display: table;
  background: #FFFFFF;
  box-shadow: 0px 9px 28px rgba(17, 48, 121, 0.18);
  border-radius: 8px;
  z-index: 1200;
  position: relative;
  width: 100%;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #04153E;
  text-align: center;
  height: 160px;
}

.hour {
  margin-top: 5px;
  max-height: 150px;
}

.minutes {
  margin-top: 5px;
  max-height: 150px;
}

.hour::-webkit-scrollbar-track {
  width: 4px;
  background: #FFFFFF;
}

.hour::-webkit-scrollbar {
  width: 4px;
  background: #e4edfb;
  border-radius: 2px;
  opacity: 0.72;
}

.hour::-webkit-scrollbar-thumb {
  width: 4px;
  background: #e4edfb;
  border-radius: 2px;
  opacity: 0.72;
}

.minutes::-webkit-scrollbar-track {
  width: 4px;
  background: #FFFFFF;
}

.minutes::-webkit-scrollbar {
  width: 4px;
  background: #e4edfb;
  border-radius: 2px;
  opacity: 0.40;
}

.minutes::-webkit-scrollbar-thumb {
  width: 4px;
  background: #e4edfb;
  border-radius: 2px;
  opacity: 0.40;
}

.time-hover {
  width: 60px;
  margin: auto;
  margin-top: 6px;
}

.time-hover:hover {
  width: 60px;
  background: #e4edfb;
  margin: auto;
  color: #3D75E4;
  border-radius: 5px;
  margin-top: 6px;
}
</style>
