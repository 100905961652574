<template>
  <div>
    <div
      class="flex sulguni mb-7 print"
      @click="back"
    >
      <r-icon
        class="mr-2"
        icon="arrow-left"
        style="margin-top: 2px;"
      />
      Назад
    </div>
    <loader v-if="isLoading" />
    <div
      v-else
      :key="countInput"
    >
      <div class="mb-3">
        <h1 class="ricotta h-tko">
          {{ elementName }}
        </h1>
      </div>
      <div class="mb-5 flex">
        <r-icon
          class="mr-2"
          icon="geopoint"
          fill="titanic"
        />
        <span class="feta h-tko titanic--text">
          {{ address }}
        </span>
      </div>
      <loader v-if="isLoading" />
      <template v-else>
        <div>
          <div class="header-link">
            <div
              :class="typeTabs === 'video' ? `mr-4 float link-information` : `mr-4 float link-information street`"
              @click="clickTabs('video')"
            >
              Видео
              <div :class="typeTabs === 'video' ? 'line' : ''" />
            </div>
            <div
              :class="typeTabs === 'sensor' ? `mr-4 float link-information` : `mr-4 float link-information street`"
              @click="clickTabs('sensor')"
            >
              Датчики
              <div :class="typeTabs === 'sensor' ? 'line' : ''" />
            </div>
            <div
              :class="typeTabs === 'map' ? `mr-4 float link-information` : `mr-4 float link-information street`"
              @click="clickTabs('map')"
            >
              На карте
              <div :class="typeTabs === 'map' ? 'line' : ''" />
            </div>
          </div>
        </div>
        <div :key="count">
          <div class="mt-8">
            <div
              v-show="typeTabs === 'video'"
              class="block-hiden-too"
            >
              <div
                style="min-height: 400px;"
                v-show="information"
                class="flex"
              >
                <video
                  ref="videoFrom"
                  width="621px"
                  id="myPlayer"
                  controls
                />
                <div
                  class="flex ml-8"
                  style="flex-direction: column;"
                >
                  <div class="mb-3">
                    <h1 class="ricotta ">
                      {{ elementName }}
                    </h1>
                  </div>
                  <div class="mb-10 flex">
                    <r-icon
                      class="mr-2"
                      icon="geopoint"
                      fill="titanic"
                    />
                    <span class="feta titanic--text">
                      {{ address }}
                    </span>
                  </div>
                  <div class="taleggio mb-6">
                    Выберите фрагмент
                  </div>
                  <r-date-picker
                    class="mb-6"
                    label="Дата"
                    v-model="date"
                    click-close
                    :is-input="false"
                  />
                  <r-time-picker
                    v-model="time"
                    :is-seconds="true"
                    label="Время"
                  />
                </div>
              </div>
              <r-button
                @click="getVideo"
                class="mt-8"
                style="max-width: 500px; margin-left: auto; margin-right: auto;"
                width="wide"
                color="rocky"
                title="Применить"
              />
            </div>
            <cam-sensor v-show="typeTabs === 'sensor'" />
            <div
              v-show="typeTabs === 'map'"
              class="edit__map"
            >
              <rir-map
                :center="coordinates"
                collapse-btn
                :panel-shown="true"
              >
                <ymap-marker
                  v-show="lat != ''"
                  :coords="[lat, lng]"
                  :marker-id="'marker'"
                  :options="{
                    hideIconOnBalloonOpen: false,
                    draggable: false,
                  }"
                  :icon="{
                    imageHref: iconBase64,
                    layout: 'default#image'
                  }"
                />

                <!--              <ymap-marker-->
                <!--                v-show="lat != ''"-->
                <!--                :coords="[lat, lng]"-->
                <!--                :marker-id="'marker'"-->
                <!--                :options="{-->
                <!--                hideIconOnBalloonOpen: false,-->
                <!--                draggable: false-->
                <!--                }"-->
                <!--                :icon="$markerIconCam"-->
                <!--              >-->
                <!--              </ymap-marker>-->
              </rir-map>
              <div
                class="message mt-3"
                v-if="information && address"
              >
                Камера по адресу: {{ address }}
              </div>
            </div>
          </div>
          <div
            v-show="information"
            v-if="false"
          >
            <div class="obj-card__info flex-1 mt-6">
              <div class="obj-card__title sulguni flex mozzarella">
                <div class="icon-search-div">
                  <r-icon
                    icon="search"
                    class="float-left block icon-search search-class"
                    size="20"
                    fill="joker"
                  />
                </div>
                <img src="../../public/logo.png">
                <div class="obj-card__info flex-1">
                  <div class="obj-card__title sulguni flex mozzarella style-marin-house ml-3 mt-1">
                    <div class="float">
                      Контейнер
                    </div>
                  </div>
                  <div
                    class="obj-card__counters flex obj-card__address mozzarella margin-class"
                    style=""
                  >
                    <div class="float-left ml-3 ">
                      Проспект имени Маршала Жукова, дом 5, строение 2
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="obj-card__info flex-1 mt-6">
              <div class="obj-card__title sulguni flex mozzarella">
                <div class="icon-search-div">
                  <r-icon
                    icon="search"
                    class="float-left block icon-search search-class"
                    fill="joker"
                  />
                </div>
                <img src="../../public/logo.png">

                <div class="obj-card__info flex-1">
                  <div class="obj-card__title sulguni flex mozzarella style-marin-house ml-3 mt-1">
                    <div class="float">
                      Контейнер
                    </div>
                  </div>
                  <div class="obj-card__counters flex obj-card__address mozzarella margin-class">
                    <div class="float-left ml-3 ">
                      Проспект имени Маршала Жукова, дом 5, строение 2
                    </div>
                  </div>
                </div>
                <div class="obj-card__info ml-1 mt-3 archive">
                  В архиве
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
    <r-modal
      ref="modal"
      close-icon
    />
  </div>
</template>

<script>
import Vue from 'vue';
import vClickOutside from 'v-click-outside';
import RirMap from '@/components/RirMap.vue';
import Hls from 'hls.js';
import axios from 'axios';
import CamSensor from '@/views/CamSensor';
import Loader from '@/components/Loader.vue';
import ArchiveVideoModal from './camers/ArchiveVideoModal';
import TimePicker from '../components/TimePicker';

Vue.use(vClickOutside);

export default {
  components: {
    Loader,
    CamSensor,
    RirMap,
    ArchiveVideoModal,
    TimePicker
  },
  props: {},
  data() {
    return {
      items: Array,
      isLoad: false,
      coordinates: [47.521196, 42.180914],
      information: true,
      count: 0,
      countInput: 0,
      element: '',
      elementName: '',
      lat: '',
      lng: '',
      angle: 0,
      address: null,
      iconBase64: '',
      typeTabs: 'video',
      date: new Date().toLocaleString('ru-Ru', {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric'
      }),
      time: new Date().getTime().toLocaleString(),
      item: null,
      globalDate: new Date()
    };
  },
  computed: {
    // пред загрузка
    isLoading() {
      return this.$store.state.isObjectLoadingId;
    }
  },
  watch: {},
  created() {
    console.log();
    const date = new Date();
    this.globalDate = date;
    this.date = `${date.getUTCFullYear()}-${date.getMonth() + 1 > 9 ? date.getMonth() + 1 : `0${date.getMonth() + 1}`}-${date.getDate() > 9 ? date.getDate() : `0${date.getDate()}`}`;
    this.time = `${date.getHours() > 9 ? date.getHours() : `0${date.getHours()}`}:${date.getMinutes() > 9 ? date.getMinutes() : `0${date.getMinutes()}`}:${date.getSeconds() > 9 ? date.getSeconds() : `0${date.getSeconds()}`}`;

    this.timeout = setTimeout(() => {
      const player = this.$refs.videoFrom;
      this.$store.dispatch('loadVideo').then(result => {
        result.forEach(element => {
          if (this.$route.params.id == element.id) {
            this.item = element;
            this.element = element.stream;
            this.elementName = element.name;
            this.lat = element.lat ? element.lat : '';
            this.lng = element.lng ? element.lng : '';
            this.angle = element.angle;
            if (this.lng != '') {
              this.coordinates = [this.lat, this.lng];

              axios.get(`https://geocode-maps.yandex.ru/1.x/?apikey=f8217e42-9b86-4033-8411-a7bf4f8d6835&geocode=${this.lng},${this.lat}&format=json&lang=ru_RU&kind=house`)
                .then(result => {
                  const geo = result.data.response.GeoObjectCollection.featureMember?.[0];

                  if (geo) {
                    this.address = geo.GeoObject.name;
                  }
                });
            }
            this.item = element;
          }
        });
        this.renderIcon();
        if (Hls.isSupported()) {
          const hls = new Hls();
          hls.loadSource(this.element);
          hls.attachMedia(player);
          hls.on(Hls.Events.MANIFEST_PARSED, () => {
            this.timeout = setTimeout(() => {
              player.play();
            }, 5000);
          });
        }
        if (this.$route.query.archive) {
          this.$modal(ArchiveVideoModal, {
            item: {
              timestamp: Math.floor(this.$route.query.archive / 1000),
              screenshot: {
                channel: {
                  id: this.item.id,
                  name: this.elementName
                }
              }
            }
          });
        }
      }, 2000);
    });
  },
  methods: {
    clickTabs(tab) {
      this.typeTabs = tab;
    },
    back() {
      const r = this.$router.resolve({
        name: 'edit',
        params: { id: this.$route.query.id }
      });

      window.location.assign(r.href);
    },
    async renderIcon() {
      const { data } = await axios.get(this.$camera.imageHref);

      const dom = await new DOMParser().parseFromString(data, 'image/svg+xml');
      const svg = dom.querySelector('svg');

      svg.style.transform = `rotate(${this.angle}deg)`;

      this.iconBase64 = `data:image/svg+xml;base64,${btoa(svg.outerHTML)}`;
    },
    async getVideo(stoped = false) {
      // https://smartvolgodonsk.ru/trassir/rest/archive/stream/<channel id>/master.m3u8?start=<unix TS в секундах>
      const player = this.$refs.videoFrom;
      const timestampStart = Math.floor(new Date(`${this.date}T${this.time}`).getTime() / 1000);

      // const res = await api.getArchiveVideo(channelId, timestamp)
      if (Hls.isSupported()) {
        const hls = new Hls();
        console.log('timestampStart', timestampStart);
        hls.loadSource(`/trassir/rest/archive/stream/${this.item.id}/master.m3u8?start=${timestampStart}`);
        hls.attachMedia(player);
        hls.on(Hls.Events.MANIFEST_PARSED, () => {
          this.timeout = setTimeout(() => {
            player.play();
          }, 2000);
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.margin-class {
  margin-top: -1px;
}

.width-class {
  width: 100%;
}

.search-class {
  position: absolute;
  padding-top: 14px;
  padding-left: 28px
}

.delete-sensor {
  padding-top: 4px;
  color: #E14761;
  cursor: pointer;
}

.time-z {
  width: 100%;
  z-index: 1400;
}

.margin-bottom {
  margin-bottom: 40px;
}

.h-tko {
  overflow: auto;
}

.icon-search-div {
  width: 76px;
  height: 48px;
  opacity: 0;
  position: absolute;
  cursor: pointer;

  &:hover {
    background: #04153E;
    opacity: 0.48;
    border-radius: 8px;
  }
}

.obj-card {
  position: relative;
  display: flex;
  background-color: #fff;
  border: 1px solid #e4edfb;
  padding: 16px 22px 16px 16px;
  border-radius: 16px;

  &__img {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    background-color: var(--rir-arrival);
    margin-right: 12px;
    width: 72px;
    height: 72px;
    border-radius: 8px;

    > img {
      height: 100%;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    width: 50%;
    float: left;
  }

  &__title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__address {
    opacity: 0.72;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__counters {
    margin-top: 1px;
  }

  &__restore {
    background-color: transparent;
    cursor: pointer;
  }
}

.pointer {
  cursor: pointer;
}

.align-center {
  //overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  position: static;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: #04153E;
}

.float {
  float: left;
}

.time {
  float: left;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: #04153E;
  opacity: 0.48;
}

.img {
  float: left;
  width: 16px;
  height: 16px;
  margin-bottom: 10px;
}

.grid-cont {
  grid-column-gap: 24px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
}

.archive {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  width: 20%;
  color: #3D75E4;
  cursor: pointer;
}

.edit {
  &__map {
    display: block;
    width: 650px;
    height: 377px;
  }
}

.search {
  color: #3D75E4;
}

.margin-info {
  margin-top: 24px;
  width: 100%;
}

.text-covid {
  display: table;
  float: right;
  width: calc(100% - 174px)
}

::v-deep .rir-file-list__details {
  padding: 6px;
}

::v-deep .rir-file-list__item .rir-file-list__img {
  min-width: 76px;
  width: 76px;
  height: 100%;
}

::v-deep .rir-file-list__item.mini .rir-file-list__info {
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
}

::v-deep .rir-file-list__info {
  color: var(--rir-titanic);
  margin-bottom: 0;
}

::v-deep .rir-file-list__size {
  font-size: 13px;
  line-height: 16px;
  color: var(--rir-rocky);
}

::v-deep .rir-drag-upload-file__remove {
  & svg {
    & path {
      fill: var(--rir-fargo);
    }
  }
}

::v-deep .rir-popover__content .rir-select__content {
  border-radius: 8px;
}

@mixin reset-list {
  font-style: normal;
  font-weight: normal;
  position: static;
}

.title-params-item {
  @include reset-list;
  left: 35%;
  font-size: 16px;
  line-height: 20px;
  color: #04153E;
  display: table;
}

.float-left {
  float: left;
}

.street-house {
  margin-top: 13px;
  @include reset-list;
  width: 100%;
  height: 30px;
  left: 24px;
  font-size: 16px;
  line-height: 20px;
  color: #04153E;
}

.street {
}

.street:hover {
  opacity: 1;
}

.print {
  cursor: pointer;
  fill: #3D75E4;
  color: #3D75E4;
}

.pointer {
  cursor: pointer;
}

.eye {
  margin-top: 1px;
}

.block {
  fill: #E14761;
  color: #E14761;
  margin-top: 1px;
}

.message {
  @include reset-list;
  width: 361px;
  height: 16px;
  left: 820px;
  top: 621px;
  font-size: 13px;
  line-height: 16px;
  color: #000000;
  margin-top: 5px;
}

.block-hiden-one {
  margin-top: 40px;
}

.margin-right {
  margin-right: 12px;
}

.icon-margin {
  position: absolute;
  margin-top: -2px;
  margin-left: -10px;
  color: #E5E5E5;
}

.line {
  position: static;
  height: 4px;
  background: #3D75E4;
  border-radius: 2px;
  margin: 8px 0px;
}

.link-information {
  cursor: pointer;
  display: table;
}

.float {
  float: left;
}

.header-link {
  margin-top: 21px;
  padding-bottom: 32px;
}

.time-days {
  width: calc(50% - 10px);
  z-index: 13;
}

.rir-input__after {
  max-height: 200px;
}

.custom {
  width: 100%;
}

.opacity {
  opacity: 1;
  border-radius: 8px;
}

.hours {
  display: table;
  background: #FFFFFF;
  box-shadow: 0px 9px 28px rgba(17, 48, 121, 0.18);
  border-radius: 8px;
  margin-top: 60px;
  z-index: 1200;
  position: relative;
  width: 100%;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #04153E;
  text-align: center;
  height: 160px;

  .hour {
    margin-top: 5px;
    max-height: 150px;
  }

  .minutes {
    margin-top: 5px;
    max-height: 150px;
  }

  .hour::-webkit-scrollbar-track {
    width: 4px;
    background: #FFFFFF;
  }

  .hour::-webkit-scrollbar {
    width: 4px;
    background: #e4edfb;
    border-radius: 2px;
    opacity: 0.72;
  }

  .hour::-webkit-scrollbar-thumb {
    width: 4px;
    background: #e4edfb;
    border-radius: 2px;
    opacity: 0.72;
  }

  .minutes::-webkit-scrollbar-track {
    width: 4px;
    background: #FFFFFF;
  }

  .minutes::-webkit-scrollbar {
    width: 4px;
    background: #e4edfb;
    border-radius: 2px;
    opacity: 0.40;
  }

  .minutes::-webkit-scrollbar-thumb {
    width: 4px;
    background: #e4edfb;
    border-radius: 2px;
    opacity: 0.40;
  }

  .time-hover {
    width: 60px;
    margin: auto;
    margin-top: 6px;
  }

  .time-hover:hover {
    width: 60px;
    background: #e4edfb;
    margin: auto;
    color: #3D75E4;
    border-radius: 5px;
    margin-top: 6px;
  }
}

.rir-list > .rir-list-item * {
  opacity: 1;
}

.delete-pribor {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #E14761;
  margin-left: 12px;
  margin-top: 32px;
  margin-bottom: 60px;
  cursor: pointer;
}

.tko {
  display: table;
  margin-left: 20px;
  margin-top: -3px;
}

@mixin reset-list {
  font-style: normal;
  font-weight: normal;
  position: static;
}

.title-conteiner {
  padding-bottom: 16px;
  width: 100%;
  display: table;
}

.title-params {
  @include reset-list;
  right: 69.8%;
  top: 10%;
  bottom: 10%;
  font-size: 13px;
  line-height: 16px;
  color: #04153E;
  opacity: 0.48;
  float: left;
  width: 151px;
  max-width: 151px;
  margin-right: 24px;
}

.lebowskis {
  color: #D06E0B;
}

.other-icon:hover::after {
  content: attr(data-title); /* Выводим текст */
  position: absolute; /* Абсолютное позиционирование */
  left: -1400%;
  top: 130%; /* Положение подсказки */
  z-index: 1; /* Отображаем подсказку поверх других элементов */
  width: 250px;
  height: 80px;
  background: #FFFFFF;
  border-radius: 12px;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: #04153E;
  padding: 8px 8px 8px 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}
</style>
