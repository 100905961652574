<template>
  <div class="data-download">
    <r-popover
      v-model="popoverState"
      position="left-bottom"
      content-class="data-download__popover"
    >
      <template #activator="{ on }">
        <button
          class="rocky--text flex align-items-center"
          transparent
          @click="on.click"
        >
          <r-icon
            class="add-icon rocky--text mr-2"
            icon="add"
          />
          <div style="margin-top: -1px">
            Загрузить данные
          </div>

          <r-icon
            class="data-download__arrow"
            :class="{'data-download__arrow--rotate': popoverState}"
            icon="arrow-down"
          />
        </button>
      </template>
      <template #content>
        <div class="data-download__container">
          <button
            class="flex"
            @click="$emit('uploadContainer', 'file')"
            transparent
          >
            <r-icon
              class="file-icon mr-2"
              icon="file"
              fill="rocky"
            />
            <div>Из файла</div>
          </button>
        </div>
      </template>
    </r-popover>
  </div>
</template>

<script>
export default {
  name: 'DataDownload',
  data() {
    return {
      popoverState: false
    };
  }
};
</script>

<style lang="scss" scoped>
.data-download {
  display: flex;
  align-items: center;
  margin-top: 5px;

  .rir-icon.add-icon {
    margin-right: 7px;
  }

  &__arrow {
    margin-left: 8px;
    transition: transform 0.2s ease;
    margin-top: 6px;

    &--rotate {
      transform: rotate(180deg);
    }
  }

  &__container {
    padding: 29px 20px;
    border-radius: 8px;
    background-color: var(--rir-amelie);

    .rir-button {
      white-space: nowrap;

      .rir-icon {
        margin-right: 12px;
      }

      span {
        color: var(--rir-titanic);
      }

      &:not(:last-child) {
        margin-bottom: 24px;
      }
    }
  }
}
</style>
