<template>
  <div class="flex">
    <div
      v-show="upload"
      class="upload"
    >
      <div>
        <div @click="upload = !upload">
          <r-icon
            class="mr-2 margin-right"
            icon="close"
            size="20"
          />
        </div>
        <div class="parent align-center">
          <div class="container-upload">
            <h1 class="ricotta mb-6">
              Поиск камер
            </h1>
            <div class="mb-6 font">
              Выберете одину или несколько найденых камер
            </div>
            <template>
              <div v-for="video in loadVideo">
                <r-checkbox
                  class="mt-1"
                  :title="'Камера ' + video.name "
                  @input="onChangeHide(video.guid, video.id, video.name)"
                  :value="check[video.id]"
                />
              </div>
            </template>
            <div class="flex mt-8 video">
              <r-button
                class="flex-1"
                :disabled="false"
                @click="save()"
              >
                Подключить
              </r-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Video',
  props: {
    item: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      upload: false,
      loadVideo: [],
      id: [],
      select: [],
      check: []
    };
  },
  mounted() {
    const v = this;
    this.$store.dispatch('loadId', this.$route.params.id);
    this.$store.dispatch('loadVideo').then(result => {
      v.loadVideo = result;
      // console.log('eue', v.loadVideo);
      v.loadVideo.forEach(item => {
        v.valIt(item.guid, item.id, item.name);
      });
    });
  },
  methods: {
    valIt(guid, id, name) {
      this.select = [];
      if (this.item) {
        this.item.forEach(item => {
          if (item != null && item.id == id) {
            this.select = '';
            this.id[id] = {
              id,
              guid,
              name,
              lat: '',
              lng: ''
            };
          }
        });
      }
      this.check[id] = this.select;
    },
    onChangeHide(guid, id, name) {
      if (this.id[id]) {
        delete this.id[id];
      } else {
        this.id[id] = {
          id,
          guid,
          name,
          lat: '',
          lng: ''
        };
      }
      // console.log(this.id)
    },
    save() {
      const item = this.$store.state.loadId.all[0];
      const id = this.id.filter(
        el => el != null
      );
      item.camera_object = id;
      this.$store.dispatch('loadSave', item).then(result => {
        const r = this.$router.resolve({
          name: 'edit',
          params: { id: this.$store.state.loadId.all[0].id }
        });
        // window.location.assign(r.href)
      });
    },
    showVideo() {
      const v = this;
      v.check = [];
      this.$store.dispatch('loadId', this.$route.params.id);
      this.$store.dispatch('loadVideo').then(result => {
        v.loadVideo = result;
        // console.log('eue', v.loadVideo);
        v.loadVideo.forEach(item => {
          v.valIt(item.guid, item.id, item.name);
          v.upload = true;
        });
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.video {
  width: 100%;
  display: inline-block;

  &__login {
    width: calc(50% - 12px);
    float: left;
  }

  &__pass {
    width: calc(50% - 12px);
    float: right;
  }
}

.align-center {
  align-items: center;
  float: left;
  margin-top: 5px;
}

.upload {
  background-color: white;
  z-index: 999;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}

.parent {
  display: block;
  position: absolute;
  width: 40%;
  height: 400px;
  top: 50%;
  margin-left: 33%;
  margin-top: -150px;
}

.container-upload {
  width: 87%;
  height: 300px;
}

.margin-right {
  float: right;
  margin-top: 17px;
  margin-right: 17px;
  z-index: 1000;
  cursor: pointer;
}

.font {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #04153E;
  opacity: 0.72;
}

</style>
