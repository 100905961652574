<template>
  <div id="app">
    <router-view />
    <r-notification />
    <r-modal
      ref="modal"
      close-icon
    />
  </div>
</template>
<script>
import Vue from 'vue';

export default {
  beforeCreate() {
    // Удаляем script от пред. приложения
    document.getElementById('vue-yandex-maps') &&
    document.getElementById('vue-yandex-maps').remove();

    // Удаляем ymaps
    window.ymaps && delete window.ymaps;
  },
  mounted() {
    Vue.prototype.$modal = this.$refs.modal.openModal;
  }
};
</script>
<style lang="scss">
.modal-open {
  overflow: hidden;
}
</style>
<style>
.RBulb__badge.fargo.marina.eluno.title.block {
  background-color: unset !important;
}

.RButtonSimple.RModal__close.larishae.transparent.rocky {
  background-color: unset !important;
}

.RButtonAction.rocky.sulguni {
  background-color: unset !important;
}
</style>
