<template>
  <div>
    <loader v-if="isLoading" />
    <div
      class="objects__list mt-6"
      v-else
    >
      <router-link
        v-for="item in filteredObjects"
        :key="item.id"
        :to="{
          name: 'edit',
          params: { id: item.id }
        }"
      >
        <div style="cursor: pointer">
          <div
            class="obj-card"
            v-if="item.type == 5"
          >
            <div>
              <r-icon
                class="mr-3"
                icon="recycling"
                fill="rocky"
                size="32"
              />
            </div>
            <div class="obj-card__info flex-1">
              <div class="obj-card__title sulguni flex roquefort">
                {{ filteredContainer[item.type] }}
              </div>
              <div
                class="obj-card__counters__address flex mt-2 mozzarella"
              >
                {{ item.address }}
              </div>
              <!--<div class="obj-card__counters flex obj-card__address mozzarella style-marin-house"
                   v-if="loadCont(item.id, item.extraData) ">
                <div class=" mr-3 img tooltipIcon" v-show="paper[item.id]">
                  <img src="../../public/icon/knob.svg">
                  <span class="tooltiptextIcon">Бумага</span>
                </div>
                <div class=" mr-3 img tooltipIcon" v-show="glass[item.id]">
                  <img src="../../public/icon/glass.svg">
                  <span class="tooltiptextIcon">Стекло</span>
                </div>
                <div class="mr-3 img tooltipIcon" v-show="metal[item.id]">
                  <img src="../../public/icon/paper.svg">
                  <span class="tooltiptextIcon">Металл</span>
                </div>
                <div class="mr-3 img tooltipIcon" v-show="plastic[item.id]">
                  <img class="other-icon" src="../../public/icon/plastic.svg">
                  <span class="tooltiptextIcon">Пластик</span>
                </div>
                <div class="mr-3 img tooltipIcon" v-show="other[item.id]">
                  <img src="../../public/icon/different.svg">
                  <span class="tooltiptextIcon">Другое</span>
                </div>
              </div>-->
              <div
                class=" obj-card__counters align-center sulguni obj-card__title flex mozzarella style-marin-house"
              >
                <div
                  class="mr-1 float flex mozzarella text-c13"
                  v-if="item.extraData != null"
                >
                  <r-icon
                    fill="rocky"
                    class="mr-1 float"
                    icon="clock"
                    size="16"
                  />
                  {{
                    item.schedule ?
                      item.schedule : item.extraData ?
                        (item.extraData.days || '') + ' ' + (item.extraData.clock || '') : '-'
                  }}
                </div>
                <div
                  class="mr-1 float tooltip mozzarella"
                  v-if="item.camera_object.length > 0"
                >
                  <svg
                    class="mr-1 ml-5 float"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M0 2C0 0.89543 0.895431 0 2 0H14C15.1046 0 16 0.89543 16 2V3C16 4.10457 15.1046 5 14 5H2C0.895431 5 0 4.10457 0 3V2ZM14 2H2V3H14V2Z"
                      fill="#D06E0B"
                    />
                    <path
                      d="M8 11C8.55229 11 9 10.5523 9 10C9 9.44771 8.55229 9 8 9C7.44772 9 7 9.44771 7 10C7 10.5523 7.44772 11 8 11Z"
                      fill="#D06E0B"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M4 12.4721V10C4 7.79086 5.79086 6 8 6C10.2091 6 12 7.79086 12 10V12.4721C13.2275 11.3735 14 9.77688 14 7.99988C14 7.70788 13.9792 7.42133 13.9392 7.14148C13.861 6.59476 14.2408 6.08816 14.7875 6.00996C15.3342 5.93176 15.8408 6.31157 15.919 6.85829C15.9724 7.23165 16 7.61284 16 7.99988C16 12.4182 12.4183 15.9999 8 15.9999C3.58172 15.9999 0 12.4182 0 7.99988C0 7.61284 0.0275596 7.23165 0.0809646 6.85829C0.159167 6.31157 0.665766 5.93176 1.21249 6.00996C1.75921 6.08816 2.13902 6.59476 2.06081 7.14148C2.02078 7.42133 2 7.70789 2 7.99988C2 9.77688 2.7725 11.3735 4 12.4721ZM6 10C6 8.89543 6.89543 8 8 8C9.10457 8 10 8.89543 10 10V13.6585C9.37444 13.8796 8.70127 13.9999 8 13.9999C7.29873 13.9999 6.62556 13.8796 6 13.6585V10Z"
                      fill="#D06E0B"
                    />
                  </svg>

                  Наблюдение
                  <span class="tooltiptext">Установлено наблюдение</span>
                </div>
              </div>
            </div>
            <div class="flex align-center">
              <additional-menu>
                <ul class="obj-card__menu">
                  <li
                    class="sulguni flex pointer"
                    @click.stop.prevent="DeleteTKO(item)"
                  >
                    <r-icon
                      class="mr-3"
                      icon="delete"
                      fill="fargo"
                    />
                    <div style="margin-top: -1px;">
                      Удалить
                    </div>
                  </li>
                </ul>
              </additional-menu>
            </div>
          </div>
          <div
            class="obj-card"
            v-else
          >
            <div>
              <r-icon
                fill="rocky"
                class="mr-4"
                icon="tko"
                v-if="item.type == 1"
                size="32"
              />
              <svg
                v-if="item.type == 2"
                class="mr-4"
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M9.31735 0.0784568C9.76795 -0.0242638 10.2362 -0.0214888 10.6855 0.0865664L31.234 5.02783C31.7025 5.14049 32.0139 5.56589 32 6.02918V9.00017C32 9.54484 31.5447 10.0002 31 10.0002L29 10.0002V30.0002C29.5523 30.0002 30 30.4479 30 31.0002C30 31.5525 29.5523 32.0002 29 32.0002L19 32.0002C18.4477 32.0002 18 31.5525 18 31.0002C18 30.4479 18.4477 30.0002 19 30.0002V12.0002C19 11.4479 19.4477 11.0002 20 11.0002C20.5523 11.0002 21 11.4479 21 12.0002V30.0002L27 30.0002V10.0002H25V28.0001C25 28.5524 24.5523 29.0001 24 29.0001C23.4477 29.0001 23 28.5524 23 28.0001V10.0001H19C18.4477 10.0001 18 9.55241 18 9.00012C18 8.44784 18.4477 8.00012 19 8.00012L30 8.00017V6.7881L10.2179 2.03113C10.0682 1.99512 9.91208 1.99419 9.76188 2.02843L1.22248 3.9751C0.68401 4.09785 0.147984 3.76085 0.0252322 3.22238C-0.0975195 2.68391 0.239487 2.14788 0.777958 2.02513L9.31735 0.0784568ZM1 16C1 14.3431 2.34315 13 4 13H13C14.6569 13 16 14.3431 16 16C16.5523 16 17 16.4477 17 17C17 17.5523 16.5523 18 16 18V28.0001C16 29.3209 15.1463 30.4425 13.9606 30.843C13.8055 31.5061 13.2104 32 12.5 32C11.8469 32 11.2913 31.5826 11.0854 31.0001H5.91463C5.70869 31.5826 5.15309 32 4.5 32C3.78957 32 3.1945 31.5061 3.0394 30.843C1.85367 30.4425 1 29.3209 1 28.0001V20.0001C1 19.4478 1.44772 19.0001 2 19.0001C2.55229 19.0001 3 19.4478 3 20.0001V28.0001C3 28.5523 3.44772 29.0001 4 29.0001H13C13.5523 29.0001 14 28.5523 14 28.0001V18H1C0.447715 18 0 17.5523 0 17C0 16.4477 0.447715 16 1 16ZM13 15H4C3.44772 15 3 15.4477 3 16H14C14 15.4477 13.5523 15 13 15ZM6 20C5.44772 20 5 20.4477 5 21C5 21.5523 5.44772 22 6 22H11C11.5523 22 12 21.5523 12 21C12 20.4477 11.5523 20 11 20H6Z"
                  fill="var(--rir-rocky)"
                />
              </svg>
              <r-icon
                fill="rocky"
                class="mr-4"
                icon="waste-bin"
                v-if="item.type == 3"
                size="32"
              />
              <svg
                class="mr-4"
                v-if="item.type == 4"
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M1 0H6C6.55228 0 7 0.447715 7 1V2H25V1C25 0.447715 25.4477 0 26 0H31C31.5523 0 32 0.447715 32 1V31C32 31.5523 31.5523 32 31 32C30.4477 32 30 31.5523 30 31V2H27V3C27 3.55228 26.5523 4 26 4H24.5664L21.8576 8.51452C21.6769 8.81573 21.3514 9.00003 21.0002 9.00003H11.0002C10.6489 9.00003 10.3234 8.81573 10.1427 8.51452L7.43394 4H6C5.44772 4 5 3.55228 5 3V2H2V31C2 31.5523 1.55228 32 1 32C0.447715 32 0 31.5523 0 31V1C0 0.447715 0.447715 0 1 0ZM11.5663 7.00003H20.434L22.234 4H9.76632L11.5663 7.00003ZM6.29289 12.7071C5.90237 12.3166 5.90237 11.6834 6.29289 11.2929C6.68342 10.9024 7.31658 10.9024 7.70711 11.2929L8.12132 11.7071C8.68393 12.2697 9 13.0328 9 13.8284V26C9 26.5523 9.44772 27 10 27H22C22.5523 27 23 26.5523 23 26V15H11C10.4477 15 10 14.5523 10 14C10 13.4477 10.4477 13 11 13H23.2068C23.3707 12.3321 23.785 11.8008 24.2929 11.2929C24.6834 10.9024 25.3166 10.9024 25.7071 11.2929C26.0976 11.6834 26.0976 12.3166 25.7071 12.7071C25.6665 12.7478 25.6248 12.7881 25.5831 12.8285C25.2939 13.1084 25 13.3927 25 13.8284V17H26C26.5523 17 27 17.4477 27 18C27 18.5523 26.5523 19 26 19H25V26C25 27.3372 24.1252 28.47 22.9166 28.8574C22.971 29.0624 23 29.2778 23 29.5C23 30.8807 21.8807 32 20.5 32C19.1193 32 18 30.8807 18 29.5C18 29.3288 18.0172 29.1616 18.05 29H13.95C13.9828 29.1616 14 29.3288 14 29.5C14 30.8807 12.8807 32 11.5 32C10.1193 32 9 30.8807 9 29.5C9 29.2778 9.02898 29.0624 9.08337 28.8574C7.87483 28.47 7 27.3372 7 26V19H6C5.44772 19 5 18.5523 5 18C5 17.4477 5.44772 17 6 17H7V13.8284C7 13.3927 6.70615 13.1084 6.41694 12.8285C6.3752 12.7881 6.33355 12.7478 6.29289 12.7071ZM20.5 29C20.2239 29 20 29.2239 20 29.5C20 29.7761 20.2239 30 20.5 30C20.7761 30 21 29.7761 21 29.5C21 29.2239 20.7761 29 20.5 29ZM11 29.5C11 29.7761 11.2239 30 11.5 30C11.7761 30 12 29.7761 12 29.5C12 29.2239 11.7761 29 11.5 29C11.2239 29 11 29.2239 11 29.5ZM19 17H13C12.4477 17 12 17.4477 12 18C12 18.5523 12.4477 19 13 19H19C19.5523 19 20 18.5523 20 18C20 17.4477 19.5523 17 19 17Z"
                  fill="var(--rir-rocky)"
                />
              </svg>
              <r-icon
                fill="rocky"
                class="mr-4"
                icon="separate"
                v-if="item.type == 5"
                size="32"
              />
            </div>
            <div class="obj-card__info flex-1 roquefort">
              <div :class="'obj-card__title sulguni flex mozzarella style-marin-house'">
                {{ filteredContainer[item.type] }}
              </div>
              <div
                :class="'obj-card__counters__address flex ' + 'obj-card__address mozzarella style-marin-house'"
              >
                {{ item.address }}
              </div>

              <div
                class=" obj-card__counters align-center sulguni obj-card__title flex mozzarella style-marin-house"
              >
                <div class="mr-1 float flex text-c13">
                  <r-icon
                    fill="rocky"
                    class="mr-1 float"
                    icon="clock"
                    size="16"
                  />
                  {{
                    (item.schedule ? item.schedule : (item.extraData ? item.extraData.days + ' ' + item.extraData.clock : ''))
                  }}
                </div>
                <div
                  class="mr-1 float tooltip mozzarella"
                  v-if="item.camera_object.length > 0"
                >
                  <svg
                    class="mr-1 ml-5 float"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M0 2C0 0.89543 0.895431 0 2 0H14C15.1046 0 16 0.89543 16 2V3C16 4.10457 15.1046 5 14 5H2C0.895431 5 0 4.10457 0 3V2ZM14 2H2V3H14V2Z"
                      fill="#D06E0B"
                    />
                    <path
                      d="M8 11C8.55229 11 9 10.5523 9 10C9 9.44771 8.55229 9 8 9C7.44772 9 7 9.44771 7 10C7 10.5523 7.44772 11 8 11Z"
                      fill="#D06E0B"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M4 12.4721V10C4 7.79086 5.79086 6 8 6C10.2091 6 12 7.79086 12 10V12.4721C13.2275 11.3735 14 9.77688 14 7.99988C14 7.70788 13.9792 7.42133 13.9392 7.14148C13.861 6.59476 14.2408 6.08816 14.7875 6.00996C15.3342 5.93176 15.8408 6.31157 15.919 6.85829C15.9724 7.23165 16 7.61284 16 7.99988C16 12.4182 12.4183 15.9999 8 15.9999C3.58172 15.9999 0 12.4182 0 7.99988C0 7.61284 0.0275596 7.23165 0.0809646 6.85829C0.159167 6.31157 0.665766 5.93176 1.21249 6.00996C1.75921 6.08816 2.13902 6.59476 2.06081 7.14148C2.02078 7.42133 2 7.70789 2 7.99988C2 9.77688 2.7725 11.3735 4 12.4721ZM6 10C6 8.89543 6.89543 8 8 8C9.10457 8 10 8.89543 10 10V13.6585C9.37444 13.8796 8.70127 13.9999 8 13.9999C7.29873 13.9999 6.62556 13.8796 6 13.6585V10Z"
                      fill="#D06E0B"
                    />
                  </svg>
                  Наблюдение
                  <span class="tooltiptext">Установлено наблюдение</span>
                </div>
              </div>
            </div>
            <div class="flex align-center">
              <additional-menu>
                <ul class="obj-card__menu">
                  <li
                    class="sulguni flex pointer"
                    @click.stop.prevent="DeleteTKO(item)"
                  >
                    <r-icon
                      class="mr-3"
                      icon="delete"
                      fill="fargo"
                    />
                    <div style="margin-top: -1px;">
                      Удалить
                    </div>
                  </li>
                </ul>
              </additional-menu>
            </div>
          </div>
        </div>
      </router-link>
    </div>

    <r-modal
      ref="modal"
      align-content-center
      close-icon
    />
  </div>
</template>

<script>
import Loader from '@/components/Loader.vue';
import AdditionalMenu from './AdditionalMenu';
import DeleteModal from './DeleteModal';

export default {
  name: 'LoadCard',
  components: {
    Loader,
    AdditionalMenu,
    DeleteModal
  },
  props: {
    check: {
      type: Boolean,
      default: false
    },
    checkHouse: {
      type: Number,
      default: null
    },
    isSearch: {
      type: String,
      default: ''
    },
    isType: {
      type: String,
      default: ''
    },
    isUnderVision: {
      type: Number,
      default: 0
    }
  },

  emits: ['count'],
  data() {
    return {
      countOnPublic: null,
      countOnModerate: null,
      paper: [],
      glass: [],
      metal: [],
      plastic: [],
      other: [],
      showDelete: false
    };
  },
  computed: {
    // Загрузка списка домов
    allObjects() {
      const { objects } = this.$store.state;
      // console.log(objects);
      return objects;
    },
    // Фильтрация списка домов
    filteredObjects() {
      let objects = [];
      if (this.allObjects != null) {
        const objectsAll = this.$store.state.objects.all.filter(el => el.hidden != '1');
        const objectsAllItem = objectsAll.map(el => ({
          id: String(el.id),
          address: String(el.address),
          type: parseInt(el.type),
          typeName: String(this.allObjects.garbageTypes[parseInt(el.type)]),
          FillingPercentage: (el.FillingPercentage ? el.FillingPercentage : ' - '),
          updated: (el.updated ? el.updated : ' - '),
          camera_guid: (!!el.camera_guid),
          extraData: el.extraData,
          schedule: el.schedule,
          extraData: {
            ...el.extraData,
            clock: el.extraData?.clock ? el.extraData?.clock : '00:00 - 00:00',
            days: el.extraData?.days ? el.extraData?.days : ''
          },
          schedule: el?.schedule ? el.schedule : '00:00 - 00:00',
          garbageType: (el.extraData ? el.extraData.garbageType : ''),
          camera_object: el.camera_object ? el.camera_object : []
        }));
        objects.push(...objectsAllItem);
        // console.log('jgf jgf', this.isType);

        const regexp = /[,\s]+|[.\s]+/g;
        const searchItem = this.isSearch.toLowerCase().replace(regexp, '');

        objects = objects.filter(el => { // @TODO refactore
          const correctAddress = el.address.toLowerCase().replace(regexp, '').includes(searchItem);
          const correctTypeName = el.typeName.toLowerCase().replace(regexp, '').includes(searchItem);

          return correctAddress || correctTypeName;
        });

        objects = objects.filter(el => { // @TODO refactore
          if (this.isType === '' || this.isType === '0') return true;

          return el.type.toString() === this.isType;
        });
        if (this.isUnderVision == 1) {
          objects = objects.filter(object => !!object.camera_object.length < 1);
        }
        if (this.isUnderVision == 2) {
          objects = objects.filter(object => !!object.camera_object.length > 0);
        }
        this.$emit('count', objects.length);
        // console.log('his.$emit(',objects.length)

        //  if(this.isUnderVision) objects = objects.filter(object => !!object['camera_object'].length > 0);
        this.$store.commit('countObjects', objects.length);
        return objects;
      }
    },
    filteredContainer() {
      const objects = [
        'Все',
        'Контейнер',
        'Площадка ТКО',
        'Бункер-накопитель',
        'Мусорокамера',
        'Раздельный сбор'
      ];

      // console.log(objects);
      return objects;
    },
    // пред загрузка
    isLoading() {
      return this.$store.state.isObjectLoading;
    }
  },
  methods: {
    async DeleteTKO(item) {
      await this.$refs.modal.openModal(DeleteModal, {
        item
      });
    },
    prettyDate2(time) {
      const dates = new Date(time);
      return `в ${dates.getHours()}:${dates.getMinutes()}`;
    },
    loadCont(id, garbage) {
      if (garbage != null) {
        this.paper[id] = false;
        this.glass[id] = false;
        this.metal[id] = false;
        this.plastic[id] = false;
        this.other[id] = false;
        const garbageItem = garbage.garbageType.split(',');
        garbageItem.forEach(element => {
          if (element == 'Бумага') {
            this.paper[id] = true;
          }
          if (element == 'Стекло') {
            this.glass[id] = true;
          }
          if (element == 'Металл') {
            this.metal[id] = true;
          }
          if (element == 'PET пластик') {
            this.plastic[id] = true;
          }
          if (element == 'Другое') {
            this.other[id] = true;
          }
        });
        return true;
      }
    },
    linkClick(id) {
      const r = this.$router.resolve({
        name: 'edit',
        params: { id }
      });
      window.location.assign(r.href);
    }
  }
};
</script>

<style lang="scss" scoped>
.tooltip .tooltiptext {
  margin-top: 30px;
  margin-left: -90px;
  visibility: hidden;
  width: 180px;
  height: 30px;
  background: #FFFFFF;
  border-radius: 12px;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: #04153E;
  padding: 8px 8px 8px 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);

  /* Разместите всплывающую подсказку */
  position: absolute;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.tooltipIcon .tooltiptextIcon {
  margin-top: 30px;
  margin-left: -30px;
  visibility: hidden;
  width: 70px;
  height: 30px;
  background: #FFFFFF;
  border-radius: 12px;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: #04153E;
  padding: 8px 8px 8px 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  opacity: 1;
  /* Разместите всплывающую подсказку */
  position: absolute;
  z-index: 1;
}

.tooltipIcon:hover .tooltiptextIcon {
  visibility: visible;
}

.other-icon:hover::after {
  content: attr(data-title); /* Выводим текст */
  position: absolute; /* Абсолютное позиционирование */
  left: -1400%;
  top: 130%; /* Положение подсказки */
  z-index: 1; /* Отображаем подсказку поверх других элементов */
  width: 250px;
  height: 80px;
  background: #FFFFFF;
  border-radius: 12px;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: #04153E;
  padding: 8px 8px 8px 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.objects {
  &__list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 4px;

    > * {
      min-width: 0;
    }
  }
}

.float-right {
  margin: 0 0 0 auto;
}

.color-card {
  color: #0e1420;
}

.obj-card {
  position: relative;
  display: flex;
  background-color: #fff;
  border: 1px solid #e4edfb;
  padding: 16px 22px 16px 16px;
  border-radius: 16px;
  height: 100%;

  &__img {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    background-color: var(--rir-arrival);
    margin-right: 12px;
    width: 72px;
    border-radius: 8px;

    > img {
      height: 100%;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  &__title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__address {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__counters {
    margin-top: 16px;
    font-size: 13px;
  }

  &__restore {
    background-color: transparent;
    cursor: pointer;
  }
}
.obj-card__counters__address {
  margin-top: 8px;
  font-size: 13px;
}
.pointer {
  cursor: pointer;
}

.align-center {
  //overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  position: static;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: #04153E;
}

.float {
  float: left;
}

.time {
  float: left;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: #04153E;
  opacity: 0.48;
}

.img {
  float: left;
  width: 16px;
  height: 16px;
  margin-bottom: 10px;
}
</style>
