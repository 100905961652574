<template xmlns="http://www.w3.org/1999/html">
  <div class="flex">
    <div class="upload">
      <div>
        <div>
          <div class="container-upload">
            <h1 class="ricotta mb-4">
              Сохранить изменения?
            </h1>
            <p class="mb-8 font">
              Вы внесли изменения в точку ТКО по адресу «{{ title }}»
            </p>
            <div class="mt-2 buttons">
              <r-button
                class="flex-1"
                type="secondary"
                width="wide"
                title="Не сохранять"
                @click="close()"
              />
              <r-button
                class="flex-1"
                @click.native="save"
                width="wide"
                title="Да, сохранить"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DeleteModal',
  props: {
    saveFunc: Function,
    title: String,
    items: Object
  },
  data() {
    return {
      upload: false
    };
  },
  methods: {
    close() {
      document.querySelectorAll('.RModal__close')[0].click();
    },
    async save() {
      this.saveFunc(this.items);
    }
  }
};
</script>

<style lang="scss" scoped>
.buttons {
  display: grid;
  grid-template-columns: 380px 380px;
  grid-gap: 32px;
  @media(max-width: 792px) {
    grid-template-columns: 1fr;
  }
}
.upload {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
}
.container-upload {
  width: 800px;
}
</style>
<style>
.RModal__close {
  z-index: 1000;
}
</style>
