<template>
  <div class="flex flex-1">
    <file-download-modal
      :load-type="loadType"
      @uploadEvent="uploadFile"
      @uploadError="uploadError"
      @closeModal="closeModal"
      v-if="upload"
    />

    <router-link :to="{ name: 'new' }">
      <span class="flex align-center sulguni upload-color">
        <r-icon
          class="mr-2"
          icon="add"
        />Добавить вручную
      </span>
    </router-link>

    <data-download
      class="ml-6"
      @uploadContainer="uploadContainer"
    />

    <!--
    <rir-checkbox
      @input="$emit('isUnderVision', underVision)"
      v-model="underVision"
      class="ml-auto"
      label="Только под наблюдением"
    ></rir-checkbox>
-->
    <div
      class="load-change"
      v-show="showUpload"
    >
      <div class="image-load">
        <img src="../../public/map/load.png">
      </div>
      <div
        class="load-text load-text-error"
        v-if="error"
      >
        Ошибка загрузки данных.
        <div class="error-load">
          Проверте файл на соответствие шаблону или повторите попытку чуть поже
        </div>
      </div>
      <div
        class="load-text load-text-end"
        v-else
      >
        Данные успешно загружены.
      </div>
      <div
        class="load-text-right"
        @click="uploadContainer"
      >
        <r-icon
          icon="restore"
          fill="fargo"
          class="float-left"
        />
        <div class="text">
          Восстановить
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DataDownload from '@/components/DataDownload.vue';
import FileDownloadModal from '@/components/FileDownloadModal.vue';

export default {
  name: 'Upload',
  components: {
    FileDownloadModal,
    DataDownload
  },
  props: ['item'],
  data() {
    return {
      load: false,
      upload: false,
      modelEnd: [],
      server: process.env.VUE_APP_API_SERVER,
      fileUpload: false,
      showUpload: false,
      error: false,
      underVision: false,
      loadType: 'file'
    };
  },
  watch: {
    upload: {
      immediate: true,
      handler() {
        const action = this.upload ? 'add' : 'remove';

        document.body.classList[action]('modal-open');
      }
    }
  },
  methods: {
    async uploadFile() {
      this.upload = false;
      this.showUpload = true;

      this.$emit('fileLoaded');

      setTimeout(this.showUp, 4000);
    },
    uploadError() {
      this.error = true;
      this.upload = false;
      this.showUpload = true;

      // console.log('error');

      setTimeout(this.showUp, 10000);
    },
    uploadContainer(loadType) {
      const popover = document.querySelector('.data-download__container');
      popover.parentElement.removeChild(popover);

      this.loadType = loadType;

      this.showUpload = false;
      this.load = false;
      this.upload = true;
    },
    showUp() {
      this.showUpload = false;
    },
    loadContainer() {
      this.showUpload = false;
      this.load = true;
      this.upload = true;
    },
    closeModal() {
      this.upload = false;
    }
  }
};
</script>

<style scoped>
.align-center {
  align-items: center;
  float: left;
  margin-top: 5px;
}

.upload-color {
  color: #3d75e4;
  cursor: pointer;
}

.load-change {
  z-index: 1000;
  position: fixed;
  height: 56px;
  width: 70%;
  left: 30%;
  right: 0%;
  top: calc(100% - 56px);
  background: #FFFFFF;
  box-shadow: 0px 8px 32px rgba(39, 20, 2, 0.12);
  border-radius: 28px;
}

.image-load {
  margin: 12px;

  float: left;
}

.load-text {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #04153E;
}

.load-text-end {
  margin-top: 18px;
}

.load-text-error {
  margin-top: 14px;
}

.load-text-right {
  cursor: pointer;
  float: right;
  margin-top: -18px;
  margin-right: 24px;
}

.text {
  float: left;
  margin-left: 7px;
  color: #E14761;
}

.float-left {
  float: left;
}

.error-load {
  position: absolute;
  height: 16px;
  left: 58px;
  top: calc(50% - 16px / 2 + 14px);
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: #34040F;
  opacity: 0.48;
}
</style>
