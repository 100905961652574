<template>
  <div class="upload">
    <div class="upload__wrapper">
      <div
        class="upload__close"
        @click="$emit('closeModal')"
      >
        <r-icon
          class="mr-2 margin-right"
          icon="close"
          fill="rocky"
          size="20"
        />
      </div>
      <div class="parent align-center">
        <div class="container-upload">
          <h1 class="upload__title ricotta mb-7">
            Загрузка данных
          </h1>
          <upload-file
            v-if="!setSettings"
            ref="childComponent"
            @uploadError="$emit('uploadError')"
            @uploadEvent="fileLoaded"
            :accept-file="['.xls']"
            :count-file="2"
            :upload-url="uploadUrl"
            :value="file"
            :count-line="1"
            description="или перетяните сюда файл xls, созданный на основе шаблона"
          />

          <file-download-settings
            v-else
            ref="settings"
          />
          <a
            :href="`/ajax.php?getImportXLStemplateForLayer=51&download=1`"
            target="_blank"
            download
            class="flex sulguni mt-6 pointer text-rocky"
          >
            <r-icon
              icon="load"
              class="mr-1"
              size="16"
              fill="rocky"
            />
            Скачать шаблон для импорта данных в xls </a>

          <div class="flex mt-7">
            <r-button
              class="flex-1"
              @click="click"
              :disabled="!!file"
              title="Загрузить"
              width="wide"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UploadFile from '@/components/dragUploadFile.vue';
import FileDownloadSettings from '@/components/FileDownloadSettings.vue';
import axios from 'axios';
import PlaceApi from "../api/PlaceApi";

export default {
  name: 'FileDownloadModal',
  components: {
    UploadFile,
    FileDownloadSettings
  },
  props: {
    loadType: {
      type: String,
      default: 'file'
    }
  },
  data() {
    return {
      file: [],
      setSettings: false,
      uploadUrl: '/ajax.php?action=universalImport',
      response: {},
      cityId: null
    };
  },
  mounted() {
    new PlaceApi().getCityId().then(res =>{
      this.cityId = res.cityId
    })
  },
  methods: {
    async click() {
      if (!this.setSettings) {
        this.$refs.childComponent.handleFiles();
      } else {
        const settings = this.getSettings();

        try {
          const { data: { error } } = await axios.post(this.uploadUrl, { filename: this.response.filename, ...settings });

          const event = error ? 'uploadError' : 'uploadEvent';

          this.$emit(event);
        } catch (e) {
          this.$emit('uploadError');
          // console.log(e);
        }
      }
    },

    fileLoaded(response) {
      this.setSettings = !this.setSettings;
      this.response = response;
    },

    getSettings() {
      const newObj = {};
      const { settings } = this.$refs.settings;
      const entries = Object.entries(settings);

      for (const [key, value] of entries) {
        newObj[key] = +value;
      }

      return newObj;
    }
  }
};
</script>

<style lang="scss" scoped>
.upload {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  background-color: white;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;

  z-index: 1400;
  overflow: auto;

  &__close {
    position: absolute;
    top: 0;
    right: 0;
  }

  &__wrapper {
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__list {
    margin-bottom: 24px;
  }

  &__link {
    font-weight: 500;
    color: var(--rir-titanic);
    border-bottom: 2px solid var(--rir-alien);
  }

  &__item {
    &:not(:last-child) {
      margin-bottom: 12px;
    }
  }
}

.parent {
  display: block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 95%;
  padding: 24px 0;
  max-width: 585px;
}

.margin-right {
  float: right;
  margin-top: 17px;
  margin-right: 17px;
  z-index: 1500;
  cursor: pointer;
}

.float-left {
  float: left;
}

.font {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #04153E;
  opacity: 0.72;
}
</style>
