<template>
  <div class="settings">
    <h2 class="settings__title">
      Настройки
    </h2>

    <ul class="settings__list">
      <li class="settings__item">
        <r-checkbox
          @input="setAll"
          title="Выбрать все"
        />
      </li>
      <li class="settings__item">
        <r-checkbox
          v-model="settings.allPages"
          title="Импортировать все листы файла"
        />
      </li>

      <li class="settings__item">
        <r-checkbox
          v-model="settings.duplicate"
          title="Дублировать значения в пустые ячейки"
        />
        <div class="settings__info">
          пустая клетка заполнится значением вышестоящей; ячейки, заполненные пробелом, не затрагиваются
        </div>
      </li>

      <li class="settings__item">
        <r-checkbox
          v-model="settings.replaceDuplicates"
          title="Заменить совпадающие записи"
        />
        <div class="settings__info">
          совпадение по первому столбцу в документе; старая запись будет скрыта
        </div>
      </li>
      <li class="settings__item">
        <r-checkbox
          v-model="settings.purge"
          title="Перед загрузкой удалить безвозвратно все данные"
        />
        <div class="settings__info">
          все данные, загруженные из файлов или с других сайтов, будут удалены
        </div>
      </li>
      <li class="settings__item">
        <r-checkbox
          v-model="settings.purgeNew"
          title="Перед загрузкой удалить только новые данные"
        />
        <div class="settings__info">
          созданные не ранее 1 часа назад
        </div>
      </li>
      <li class="settings__item">
        <r-checkbox
          v-model="settings.parseAddress"
          title="Распознать адрес в координаты, для отрисовки значков на карте"
        />
        <div class="settings__info">
          в файле нужны пустые столбцы lat, lng; настройте область города в настройках
        </div>
      </li>
      <li class="settings__item">
        <r-checkbox
          v-model="settings.ignoreApi"
          title="Пропускать ошибки поиска адреса"
        />
        <div class="settings__info">
          игнорировать отсутствие API ключа
        </div>
      </li>
      <li class="settings__item">
        <r-checkbox
          v-model="settings.commaIgnore"
          title="Игнорировать запятые значения адрес"
        />
        <div class="settings__info">
          каждая запятая разбивает значение ячейки, создаются дубликаты строки
        </div>
      </li>
      <li class="settings__item">
        <r-checkbox
          v-model="settings.dateFormatChange"
          title="Перевести даты в формат ГГГГ-ММ-ДД"
        />
        <div class="settings__info">
          для всех столбцов типа «дата»
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'FileDownloadSettings',
  data() {
    return {
      settings: {
        save: true,
        allPages: false,
        duplicate: false,
        replaceDuplicates: false,
        purge: false,
        purgeNew: false,
        parseAddress: false,
        ignoreApi: false,
        commaIgnore: false,
        dateFormatChange: false
      }
    };
  },
  methods: {
    setAll() {
      const items = Object.entries(this.settings);

      for (const [key, value] of items) {
        this.settings[key] = !value;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.settings {
  margin-top: 32px;

  &__title {
    font-size: 20px;
    margin-bottom: 16px;
  }

  &__item {
    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }

  &__info {
    opacity: 0.48;
    font-size: 13px;
    margin-left: 29px;
  }
}
</style>
