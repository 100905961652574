<template>
  <section
    class="flex"
    style="flex-direction: column;"
  >
    <span class="cam-date mb-1 text-titanic">{{ date }}</span>
    <span class="cam-time text-titanic opacity-48">{{ time }}</span>
  </section>
</template>

<script>
export default {
  name: 'CamDate',
  props: {
    item: {
      type: Object
    }
  },
  data: () => ({
    date: null,
    time: null
  }),
  mounted() {
    const date = new Date(this.item.timestamp * 1000);
    this.date = `${date.getDate() > 9 ? date.getDate() : `0${date.getDate()}`}.${date.getMonth() + 1 > 9 ? date.getMonth() + 1 : `0${date.getMonth() + 1}`}.${date.getUTCFullYear()}`;
    this.time = `${date.getHours() > 9 ? date.getHours() : `0${date.getHours()}`}:${date.getMinutes() > 9 ? date.getMinutes() : `0${date.getMinutes()}`}:${date.getSeconds() > 9 ? date.getSeconds() : `0${date.getSeconds()}`}`;
  }
};
</script>

<style lang="scss">
.cam-date {
  font-size: 16px!important;
  line-height: 20px!important;
  color: #04153E;
}
.cam-time {
  font-size: 13px!important;
  line-height: 16px!important;
  color: #04153E;
  opacity: 0.48;
}
</style>
