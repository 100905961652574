<template>
  <r-popover
    content-class="time-picker__content"
  >
    <template #activator="{ on }">
      <r-input
        label="Время"
        class="time-picker__input"
        readonly
        :value="value"
        :is-clear="false"
      >
        <a
          @click="on.click"
          slot="after"
          class="pointer time-picker__icon"
        >
          <r-icon
            icon="clock"
            size="16"
            fill="rocky"
          />/>
        </a>
      </r-input>
    </template>
    <div class="flex flex-column time-picker__body">
      <div class="px-6 pt-5 pb-4 flex align-center justify-center opacity-48 sulguni">
        Время
      </div>
      <div class="flex time-picker__numbers">
        <div>
          <a
            v-for="hour in hourList"
            :key="hour"
            :class="{
              active: hour === model[0]
            }"
            @click="setHour(hour)"
          >
            {{ hour }}
          </a>
        </div>
        <div>
          <a
            v-for="minute in minuteAndSecondList"
            :key="minute"
            :class="{
              active: minute === model[1]
            }"
            @click="setMinute(minute)"
          >
            {{ minute }}
          </a>
        </div>
        <div>
          <a
            v-for="second in minuteAndSecondList"
            :key="second"
            :class="{
              active: second === model[2]
            }"
            @click="setSecond(second)"
          >
            {{ second }}
          </a>
        </div>
      </div>
    </div>
  </r-popover>
</template>

<script>
export default {
  name: 'TimePicker',
  props: {
    value: {
      type: String
    }
  },
  computed: {
    hourList() {
      return new Array(24).fill(1).map((el, index) => (index < 10 ? `0${index}` : `${index}`));
    },
    minuteAndSecondList() {
      return new Array(60).fill(1).map((el, index) => (index < 10 ? `0${index}` : `${index}`));
    },
    model() {
      return this.value.split(':');
    }
  },
  methods: {
    setHour(hour) {
      this.$emit('input', `${hour}:${this.model[1]}:${this.model[2]}`);
    },
    setMinute(minute) {
      this.$emit('input', `${this.model[0]}:${minute}:${this.model[2]}`);
    },
    setSecond(second) {
      this.$emit('input', `${this.model[0]}:${this.model[1]}:${second}`);
    }
  }
};
</script>

<style lang="scss">
.time-picker{
  &__icon{
    pointer-events: auto!important;
    cursor: pointer!important;
  }
  &__input{
    opacity: 1!important;
  }
  &__content{
    width: 100%;
    max-width: 100%;
  }
  &__body{
    width: 100%;
    flex: 0 0 auto;
  }
  &__numbers{
    height: 206px;
    overflow: hidden;
    padding: 0 8px;
    & > div {
      height: 100%;
      width: 33.33333%;
      flex: 0 0 33.33333%;
      display: flex;
      flex-direction: column;
      align-items: center;
      overflow: auto;
      & > a {
        width: 24px;
        height: 24px;
        text-align: center;
        display: block;
        color: #113079;
        padding: 4px 2px;
        border-radius: 4px;
        cursor: pointer;
        margin-bottom: 8px;
        font-weight: 500;
        font-size: 13px;
        line-height: 16px;
        &.active{
          background-color: #3D75E4;
          color: #FFFFFF;
        }
        &:hover{
          background-color: #E4EDFB;
          color: #3D75E4;
        }
      }
    }
  }
}
</style>
