<template>
  <div
    class="flex"
    style="flex-direction: column;"
  >
    <div class="flex justify-space-between align-center mb-6">
      <r-select
        class="flex-auto mr-6"
        label="Тип"
        v-model="filter.event"
        :items="eventsType"
        id-value="code"
        text-value="name"
      />
      <r-date-picker
        class="flex-auto"
        label="Дата"
        v-model="filter.date"
        click-close
        :is-input="false"
      />
    </div>
    <div class="flex justify-end align-center mb-2">
      <span class="text-titanic parmigiano opacity-48">Всего {{ list.length }}</span>
    </div>
    <r-table
      :headers="headers"
      v-for="item in list"
      :items="[item]"
      color-header="amelie"
    >
      <template #CamScreenshot>
        <cam-screenshot :item="item" />
      </template>
      <template #CamType>
        <cam-type :item="item" />
      </template>
      <template #CamDate>
        <cam-date :item="item" />
      </template>
      <template #CamVideo>
        <cam-video :item="item" />
      </template>
    </r-table>
  </div>
</template>

<script>
import PlaceApi from '@/api/PlaceApi';
import RirSelect from '@/components/SelectType';
import CamScreenshot from '@/views/camers/CamScreenshot';
import CamType from '@/views/camers/CamType';
import CamDate from '@/views/camers/CamDate';
import ModalScreenshot from '@/views/camers/ModalScreenshot';
import CamVideo from '@/views/camers/CamVideo';

const api = new PlaceApi();
export default {
  name: 'CamSensor',
  components: { RirSelect, CamScreenshot, CamType, CamDate, CamVideo },
  data: () => ({
    count: 0,
    eventsType: [
      {
        id: 3,
        code: 'SMOKE_DETECTED',
        title: 'Дым'
      },
      {
        id: 5,
        code: 'OBJECT_SIZE_ALARM',
        title: 'Крупногабаритный транспорт'
      },
      {
        id: 6,
        code: 'FIRE_DETECTED',
        title: 'Пожар'
      }
    ],
    filter: {
      event: 'SMOKE_DETECTED',
      date: new Date().toLocaleDateString('en-CA')
    },
    list: [],

    headers: [
      {
        name: 'Скриншот',
        field: 'CamScreenshot',
        slot: true
      },
      {
        name: 'Тип',
        field: 'CamType',
        slot: true
      },
      {
        name: 'Дата',
        field: 'CamDate',
        slot: true
      },
      {
        name: '',
        field: 'CamVideo',
        slot: true
      }
    ]
  }),
  watch: {
    filter: {
      handler() {
        this.getSensor();
      },
      deep: true
    }
  },
  mounted() {
    this.getSensor();
  },
  methods: {
    countLoad() {
      this.count++;
    },
    async getSensor() {
      const { data } = await api.getSensorCam('/trassir/rest/commonEvent', {
        ch: this.$route.params.id,
        event: this.filter.event,
        to: this.filter.date,
        from: this.filter.date
      });
      let count = 1;
      document.querySelectorAll('.RTable').forEach(item => {
        if (count > 1) {
          item.querySelector('thead').style.visibility = 'collapse';
        }
        count++;
      });
      this.list = data.map(el => ({
        ...el,
        typeName: this.eventsType.find(ev => ev.code === el.eventType)
      }));
    }
  }
};
</script>

<style lang="scss">
//.rir-table{
//  & > tbody {
//    & > tr {
//      &:hover{
//        & > * {
//          color: #113079!important;
//        }
//      }
//    }
//  }
//}
</style>
