import Vue from 'vue';
import Vuex from 'vuex';
import PlaceApi from '@/api/PlaceApi';

const api = new PlaceApi();

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    countObjects: 0,
    types: null,
    objects: null,
    isObjectLoading: null,
    isObjectLoadingId: null,
    loadId: null,
    save: null,
    poly: null,
    delete: null,
    video: null,
    camItem: null,
    isEmpty: false
  },
  mutations: {
    countObjects(state, countObjects) {
      state.countObjects = countObjects;
    },
    setObjects(state, objects) {
      state.objects = objects;
    },
    setLoadId(state, objects) {
      state.loadId = objects;
    },
    setSave(state, objects) {
      state.save = objects;
    },
    setDelete(state, objects) {
      state.delete = objects;
    },
    setObjectLoading(state, status) {
      state.isObjectLoading = status;
    },
    setObjectLoadingId(state, status) {
      state.isObjectLoadingId = status;
    },
    setCoors(state, objects) {
      state.coors = objects;
    },
    setCovid(state, objects) {
      state.covid = objects;
    },
    setCovidCancel(state, objects) {
      state.covidCancel = objects;
    },
    setPoly(state, objects) {
      state.poly = objects;
    },
    setVideo(state, objects) {
      state.video = objects;
    },
    setCamItem(state, objects) {
      state.camItem = objects;
    },
    setIsEmpty(state, status) {
      state.isEmpty = status;
    }
  },
  getters: {
    getLoadId: () => state.objects,
    getSave: () => state.objects,
    getDelete: () => state.objects,
    getCoors: () => state.objects,
    getCovid: () => state.objects,
    getCovidCancel: () => state.objects,
    getPoly: () => state.objects,
    getVideo: () => state.objects
  },
  actions: {
    async loadId(ctx, id) {
      ctx.commit('setObjectLoadingId', true);
      const res = await api.getLoadId(id);
      ctx.commit('setLoadId', res);
      ctx.commit('setObjectLoadingId', false);

      return res;
    },
    async loadObjects(ctx, typeId = 0) {
      ctx.commit('setObjectLoading', true);
      const res = await api.getList({ readOnly: 1, type: typeId, currentMainMenuNumber: 5 });
      ctx.commit('setObjects', res);
      ctx.commit('setObjectLoading', false);
      if (res.length < 1) {
        ctx.commit('setIsEmpty', false);
      }
    },
    async loadSave(ctx, item) {
      ctx.commit('setObjectLoadingId', true);

      const res = await api.getSave({
        id: item.id,
        action: 'update',
        item
      });
      // console.log('load save', res);
      ctx.commit('setSave', res);

      return res;
    },
    async loadDelete(ctx, item) {
      ctx.commit('setObjectLoadingId', true);
      const res = await api.getDelete({
        id: item.id,
        action: 'delete',
        item
      });
      // console.log(item.id);
      // console.log('load save', res);
      ctx.commit('setDelete', res);

      return res;
    },
    async loadCoors(ctx, item) {
      const res = await api.getCoors();
      // console.log('load coors', res);
      ctx.commit('setCoors', res);

      return res;
    },
    async loadCovid(ctx, item) {
      const res = await api.getCovid(item.active, item.id);
      // console.log('load coors', res);
      ctx.commit('setCovid', res.types);
    },
    async loadCovidCancel(ctx, item) {
      const res = await api.getCovidCancel(item.active, item.id);
      // console.log('load coors', res);
      ctx.commit('setCovidCancel', res.types);
    },
    async loadPoly(ctx, item) {
      const res = await api.getPoly(item.active);
      // console.log('load coors', res);
      ctx.commit('setPoly', res.types);

      return res;
    },
    async loadVideo(ctx) {
      const res = await api.getVideo();
      ctx.commit('setVideo', res.types);

      return res;
    }

  },
  modules: {}
});
