<template>
  <section class="type-name text-titanic">
    {{ item.typeName.name }}
  </section>
</template>

<script>
export default {
  name: 'CamScreenshot',
  props: {
    item: {
      type: Object
    }
  }
};
</script>

<style lang="scss">

.type-name {
  font-size: 16px!important;
  line-height: 20px!important;
}
</style>
